const ScheduleAndGoalsEnums = Object.freeze({
  ONE_TIME: 'one_time',
  PERIODIC: 'periodic',
  EVENT_TRIGGERED: 'event_trigger',
  DEVICE_TRIGGERED: 'device_trigger',
  LOCATION_TRIGGERED: 'location_trigger',
  IMMEDIATELY: 'Immediately',
  AT_SPECIFIC_DATE_AND_TIME: 'at_specific_date_and_time',
  SEND_IN_USER_TIME_ZONE: 'send_in_user_time_zone',
  BEST_TIME_FOR_USER: 'best_time_for_user',
  BEST_TIME: 'Best time',
  TIME_OF_DAY: 'Time of Day (In UTC)',
  USERS: 'Users',
  AT_FIXED_TIME: 'at_fixed_time',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  DAY_OF_MONTH: 'Date of Month',
  DAY_OF_WEEK: 'Day of Week',
  ON: 'On',
  AFTER: 'After',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  NEVER: 'Never',
  YES_SEND_AT_START_TIME: '0',
  SEND_MESSAGE_AT_START_OR_END_TIME: 'true',
  ACTIVE_CONTINOUSLY: 'Active Continously',
  YES_SEND_IT: 'true',
  OBJECT_KEY_AT_IMMEDIATELY: 'immediately',
  OBJECT_KEY_AT_FIXED_TIME: 'atFixedTime',
  OBJECT_KEY_SEND_IN_USER_TIME_ZONE: 'sendInUserTimeZone',
  OBJECT_KEY_BEST_TIME_FOR_USER: 'bestTimeForUser',
  BEST_TIME_FORMAT: 'timeFormat',
  BEST_TIME_MINUTE: 'timeMinutes',
  BEST_TIME_HOURS: 'timeHours',
  OBJECT_KEY_On: 'on',
  OBJECT_KEY_After: 'after',
  REQUEST_LIMIT_PLACEHOLDER: 'Request Limit',
  AS_SOON_AS_POSSIBLE: 'as_soon_as_possible',
  AT_SPECIFIC_TIME: 'at_specific_time',
  SMS: 'tata_smsc',
  WHATSAPP: 'tata_whatsapp',
  EMAIL: 'email',
  PUSH: 'push',
  IN_APP: 'in_app',
  EVENT: 'event',
  ASIAKOLKATA: 'Asia/Kolkata',
  ENDTIME: 'endTime',
  SENDTIME: 'sendTime',
  NEXT_RUN_TIME: 'nextRunTime',
  LIMITED_PERIOD: 'limited',
  ALWAYS: 'always',
  Timebound: 'Timebound',
  BEST_TIME_OUTSIDE_VALUE: {
    ZERO: 'message_at_start_time',
    ONE: 'message_at_end_time',
    TWO: 'message_at_app_best_time',
    THREE: 'message_do_not_send',
  },
  BEST_TIME_IS_NOT_AVAILABLE_VALUE: {
    ZERO: 'at_start_time',
    ONE: 'at_end_time',
    TWO: 'do_not_send',
  },
  TIME_MINUTES: 'TIME_MINUTES',
  TIME_HOURS: 'TIME_HOURS',
  AM: 'AM',
  PM: 'PM',
});

export default ScheduleAndGoalsEnums;
