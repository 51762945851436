import {
  GET_FAILED_EVENTS,
  GET_FAILED_EVENTS_SUCCESS,
  GET_RECENT_EVENTS,
  GET_RECENT_EVENTS_SUCCESS,
} from 'redux/constants';

export const getRecentEventList = (payload) => ({
  type: GET_RECENT_EVENTS,
  payload,
});

export const getRecentEventListSuccess = (items) => ({
  type: GET_RECENT_EVENTS_SUCCESS,
  payload: items,
});

export const getFailedEventList = (payload) => ({
  type: GET_FAILED_EVENTS,
  payload,
});

export const getFailedEventListSuccess = (items) => ({
  type: GET_FAILED_EVENTS_SUCCESS,
  payload: items,
});
