import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import axios from 'axios';
import {
  apiUrlNewV3,
  apiUrlNew,
  getHeaders,
  commonRequestException,
} from 'helpers/ApiHelper';
import { userCountFilterSegmentReset } from 'redux/segmentation/actions';
import CommonEnums from 'enums/commonEnums';
import {
  CAMPAIGNS_GET,
  CAMPAIGNS_DELETE,
  CAMPAIGNS_ADD,
  CAMPAIGNS_CREATE_TYPE_ADD,
  SMS_CAMAPAIGNS_TEMPLATES_GET_ALL,
  SMS_CAMAPAIGN_TEMPLATE_DELETE,
  SMS_CAMAPAIGN_TEMPLATE_ADD,
  SMS_CAMAPAIGN_TEMPLATE_UPDATE,
  SMS_CAMAPAIGNS_GET_ALL_SENDER_ID,
  SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID,
  SCHEDULE_SMS_CAMPAIGN,
  SMS_CAMAPAIGN_TAGS,
  SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID,
  SMS_CONTENT_GENERATE_TINY_URL,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG,
  TEST_SMS_CAMPAIGN,
  TEST_EMAIL_CAMPAIGN,
  SMS_CAMPAIGN_SAVE_AS_DRAFT,
  FETCH_TEMPLATES_REQUEST,
  FETCH_EMAIL_TEMPLATES_REQUEST,
  GET_EMAIL_ADDRESS,
  ADD_EMAIL_TEMPLATE,
  GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST,
  TEST_WHATSAPP_CAMPAIGN,
  GET_CAMPAIGN_INFO_BY_ID,
  GET_EMAIL_TEMPLATE_BY_ID,
  DELETE_EMAIL_CAMPAIGN_TEMPLATE,
  GET_PUSH_TEMPLATE,
  GET_INAPP_TEMPLATE,
  SAVE_INAPP_TEMPLATE,
  UPDATE_INAPP_TEMPLATE,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE,
  DELETE_PUSH_TEMPLATE,
  SAVE_PUSH_TEMPLATE,
  UPDATE_PUSH_TEMPLATE,
  DELETE_INAPP_TEMPLATE,
  GENERATE_PERSONALIZED_PUSH_MSG,
  PUSH_CONTENT_GENERATE_TINY_URL,
  TEST_PUSH_CAMPAIGN,
  GET_BEST_TIME_FOR_USERS,
  CHECK_CAMPAIGN_NAME,
  TEST_INAPP_CAMPAIGN,
} from 'redux/constants';

import {
  getCampaignsSuccess,
  deleteCampaignSuccess,
  deleteCampaignError,
  addCampaignSuccess,
  addCampaignError,
  addCampaignCreateTypeSuccess,
  addSmsCampaignTemplateError,
  getSmsCampaignsTemplatesAllSuccess,
  deleteSmsCampaignTemplateSuccess,
  deleteSmsCampaignTemplateError,
  addSmsCampaignTemplateSuccess,
  updateSmsCampaignTemplateSuccess,
  updateSmsCampaignTemplateError,
  getAllSenderIdSuccess,
  getAllTemplatesBasedOnSenderIdError,
  getAllTemplatesBasedOnSenderIdSuccess,
  scheduleSmsCampaignSuccess,
  scheduleSmsCampaignError,
  getSmsCampaignsTagsSuccess,
  getTemplatesMessageBasedOnTemplateIdSuccess,
  getTemplatesMessageBasedOnTemplateIdError,
  generateTinyUrlSuccess,
  testSmsCampaignSuccess,
  testSmsCampaignError,
  testEmailCampaignSuccess,
  testEmailCampaignError,
  getPersonalizedMsgSuccess,
  smsCampaignSaveAsDraftSuccess,
  smsCampaignSaveAsDraftError,
  fetchTemplatesSuccess,
  fetchTemplatesFailure,
  fetchEmailTemplatesSuccess,
  fetchEmailTemplatesFailure,
  getEmailAddressSuccess,
  addEmailTemplateSuccess,
  addEmailTemplateFailure,
  smsCampaignSaveAsDraftClean,
  getWhatsAppCampaignTemplatesListSuccess,
  getWhatsAppCampaignTemplatesListDetailed,
  testWhatsAppCampaignSuccess,
  testWhatsAppCampaignError,
  getCampaignInfoByIdSuccess,
  deleteEmailTemplateSuccess,
  deleteEmailCampaignTemplateError,
  getPushTemplateListSuccess,
  setPushListError,
  getInAppTemplateListSuccess,
  setInAppListError,
  saveInappTemplateSuccess,
  saveInappTemplateFailure,
  updateInappTemplateSuccess,
  updateInappTemplateFailure,
  getPersonalizedMsgTitleSuccess,
  getPersonalizedPushMsgSuccess,
  deletePushTemplateSuccess,
  savePushTemplateSuccess,
  updatePushTemplateSuccess,
  deleteInappTemplateSuccess,
  deleteInappTemplateError,
  generatePushTinyUrlSuccess,
  testPushCampaignError,
  testPushCampaignSuccess,
  getBestTimeForUsersSuccess,
  checkCampaignNameSuccess,
  checkCampaignNameError,
  testInappCampaignSuccess,
  testInappCampaignError,
} from './actions';
import ContentConfigurationEnums from 'enums/campaigns/ContentConfigurationEnums';

const getCampaignsAsync = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNew()}campaigns`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getCampaigns({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getCampaignsAsync, payload);
    yield put(getCampaignsSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const deleteCampaignRequest = async (postData) => {
  const method = 'delete';
  return axios[method](`${apiUrlNew()}campaigns/${postData.id}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* deleteCampaign({ payload }) {
  try {
    const response = yield call(deleteCampaignRequest, payload);
    yield put(deleteCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteCampaignError(errorMsg));
  }
}

const addCampaignRequest = async (postData) => {
  const method = postData.id ? `patch` : 'post';
  const itemIdStr = postData.id ? `/${postData.id}` : '';
  return axios[method](`${apiUrlNew()}campaigns${itemIdStr}`, postData, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* addCampaign({ payload }) {
  try {
    const response = yield call(addCampaignRequest, payload);
    yield put(addCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(addCampaignError(errorMsg));
  }
}

function* addCampaignCreateType({ payload }) {
  yield put(addCampaignCreateTypeSuccess(payload));
}

const getSmsTemplatesAsync = async ({
  search_term,
  page,
  selectedPageSize,
  templateType,
}) => {
  const method = 'get';

  let apiUrl = `${apiUrlNewV3()}templates?template_type=${templateType}&page=${page}&per_page=${selectedPageSize}`;

  if (search_term) {
    apiUrl += `&search_term=${search_term}`;
  }

  const res = await axios[method](apiUrl, {
    headers: getHeaders(),
  });
  return res.data;
};

const getAllSenderIdAsync = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}channels/fetch_all_sender_id`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data.sender_id;
  });
};

function* getAllSmsTemplates({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getSmsTemplatesAsync, payload);
    yield put(getSmsCampaignsTemplatesAllSuccess({ response, payload }));
  } catch (error) {
    commonRequestException(error);
  }
}

const getPushTemplatesAsync = async ({
  page,
  selectedPageSize,
  templateType,
  target_platform,
  search_term,
}) => {
  const method = 'get';
  let apiUrl = `${apiUrlNewV3()}templates?template_type=${templateType}&page=${page}&per_page=${selectedPageSize}`;

  if (target_platform) {
    apiUrl += `&target_platform=${target_platform}`;
  }
  if (search_term) {
    apiUrl += `&search_term=${search_term}`;
  }

  const res = await axios[method](apiUrl, {
    headers: getHeaders(),
  });
  return res.data;
};

function* getAllTemplates({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getPushTemplatesAsync, payload);
    yield put(getPushTemplateListSuccess({ response, payload }));
  } catch (error) {
    commonRequestException(error);
  }
}

const deleteSMSTemplateRequest = async (template) => {
  const method = 'delete';
  return axios[method](`${apiUrlNewV3()}templates/${template.id}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* deleteSmsTemplate({ payload }) {
  try {
    const response = yield call(deleteSMSTemplateRequest, payload);
    yield put(deleteSmsCampaignTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteSmsCampaignTemplateError(errorMsg));
  }
}

function* deletePushTemplate({ payload }) {
  try {
    const response = yield call(deleteSMSTemplateRequest, payload);
    yield put(deletePushTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteSmsCampaignTemplateError(errorMsg));
  }
}

const addOrUpdateSmsTemplateRequest = async (template) => {
  const method = template.id ? `patch` : 'post';
  const itemIdStr = template.id ? `/${template.id}` : '';
  return axios[method](`${apiUrlNewV3()}templates${itemIdStr}`, template, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* addSmsTemplateRequest({ payload }) {
  try {
    const response = yield call(addOrUpdateSmsTemplateRequest, payload);
    yield put(addSmsCampaignTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(addSmsCampaignTemplateError(errorMsg));
  }
}

function* addPushTemplateRequest({ payload }) {
  try {
    const response = yield call(addOrUpdateSmsTemplateRequest, payload);
    yield put(savePushTemplateSuccess({ response }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(addSmsCampaignTemplateError(errorMsg));
  }
}

function* updatePushTemplate({ payload }) {
  try {
    const response = yield call(addOrUpdateSmsTemplateRequest, payload);
    yield put(updatePushTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(updateSmsCampaignTemplateError(errorMsg));
  }
}

function* updateSmsTemplate({ payload }) {
  try {
    const response = yield call(addOrUpdateSmsTemplateRequest, payload);
    yield put(updateSmsCampaignTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(updateSmsCampaignTemplateError(errorMsg));
  }
}

const getCampaignTagsAsync = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}campaign_tags`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data.campaign_tags;
  });
};

function* getSmsCampaignsTags() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getCampaignTagsAsync);
    yield put(getSmsCampaignsTagsSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

function* getAllSenderId() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getAllSenderIdAsync);
    yield put(getAllSenderIdSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const getAllTemplateBasedOnSenderIdRequest = async (payload) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}templates/get_template_id_by_sender_id`,
    { sender_id: payload.senderId },
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* getAllTemplateBasedOnSenderId({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getAllTemplateBasedOnSenderIdRequest, payload);
    yield put(getAllTemplatesBasedOnSenderIdSuccess(response));
  } catch (error) {
    getAllTemplatesBasedOnSenderIdError(error);
  }
}
const getTemplateBasedOnTemplateIdRequest = async (payload) => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}templates/${payload.templateId}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getTemplateBasedOnTemplateId({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getTemplateBasedOnTemplateIdRequest, payload);
    yield put(getTemplatesMessageBasedOnTemplateIdSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(getTemplatesMessageBasedOnTemplateIdError(errorMsg));
  }
}

const scheduleSmsCampaignRequest = async ({ payload, id, actionType }) => {
  const method = id ? 'put' : 'post';
  const query = id
    ? `campaigns/${id}/${actionType ?? 'publish'}`
    : 'campaigns/publish';
  return axios[method](`${apiUrlNewV3()}${query}`, payload, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* scheduleSmsCampaign({ payload }) {
  try {
    const response = yield call(scheduleSmsCampaignRequest, payload);
    yield put(scheduleSmsCampaignSuccess({ response }));
    yield put(userCountFilterSegmentReset());
    yield put(smsCampaignSaveAsDraftClean());
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(scheduleSmsCampaignError(errorMsg));
    yield put(smsCampaignSaveAsDraftClean());
  }
}

const generateTinyUrlRequest = async (payload) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}generate`,
    {
      url: payload,
    },
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* generateTinyUrl({ payload }) {
  try {
    let response = yield call(generateTinyUrlRequest, payload);

    const tinyUrls = [];
    payload.forEach((val) => {
      const res = response.find(
        (obj) => obj?.url?.url === val || val.includes(obj?.url?.url)
      );
      tinyUrls.push({
        initialURL: val,
        tinyUrl: res?.shorturl || '',
      });
    });

    yield put(generateTinyUrlSuccess(tinyUrls));
  } catch (error) {
    commonRequestException(error);
  }
}

function* generatePushTinyUrl({ payload }) {
  try {
    let payloadResp = payload?.map((res) => {
      return res?.url;
    });
    let response = yield call(generateTinyUrlRequest, payloadResp);
    const tinyUrls = [];
    payload.forEach((val) => {
      const res = response.find((obj) => {
        return obj?.url?.url === val?.url;
      });
      tinyUrls.push({
        initialURL: val?.url,
        tinyUrl: res?.shorturl || '',
      });
    });

    let modifyData = {
      [payload[0]?.componentType]: tinyUrls,
    };

    yield put(generatePushTinyUrlSuccess(modifyData));
  } catch (error) {
    commonRequestException(error);
  }
}

const getPersonalizedMsgRequest = async ({
  payload,
  channelType,
  componentType,
}) => {
  const method = 'post';
  let mappingObj = {};
  payload?.mapping?.forEach((field) => {
    mappingObj = { ...mappingObj, [field.key]: field.value };
  });

  const res = await axios[method](
    `${apiUrlNewV3()}campaigns/personalize_message`,
    {
      message: payload?.message,
      mapping:
        channelType === CommonEnums.SMS || channelType === CommonEnums.PUSH
          ? mappingObj
          : { custom_params: payload?.mapping },
      campaign_channel_type: channelType,
    },
    {
      headers: getHeaders(),
    }
  );

  if (channelType === CommonEnums.PUSH) {
    return {
      [componentType]: {
        existing_msg: payload?.message,
        personalize_message: res?.data?.data?.personalize_message,
        personalise_mapping_attribute:
          res?.data?.data?.personalise_mapping_attribute,
      },
    };
  } else {
    return {
      existing_msg: payload.message,
      personalize_message: res.data.data.personalize_message,
      personalise_mapping_attribute:
        res.data.data.personalise_mapping_attribute,
    };
  }
};

function* getPersonalizedMsg({ payload }) {
  try {
    const response = yield call(getPersonalizedMsgRequest, payload);
    yield put(
      getPersonalizedMsgSuccess({
        personalizedMsg: response,
      })
    );
  } catch (error) {
    commonRequestException(error);
  }
}

function* getPersonalizedMsgTitle({ payload }) {
  try {
    const response = yield call(getPersonalizedMsgRequest, payload);
    yield put(
      getPersonalizedMsgTitleSuccess({
        personalizedMsg: response,
      })
    );
  } catch (error) {
    commonRequestException(error);
  }
}

function* getPersonalizedPushMsg({ payload }) {
  try {
    const response = yield call(getPersonalizedMsgRequest, payload);
    yield put(
      getPersonalizedPushMsgSuccess({
        personalizedMsg: response,
      })
    );
  } catch (error) {
    commonRequestException(error);
  }
}

const testSmsCampaignRequest = async (testData) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}campaigns/test_sms_message_via_tatasms`,
    testData,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* smsTestCampaign({ payload }) {
  try {
    const response = yield call(testSmsCampaignRequest, payload);
    yield put(testSmsCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(testSmsCampaignError(errorMsg));
  }
}

const testEmailCampaignRequest = async (testData) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}campaigns/test_campaign_via_tataemail`,
    testData,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* emailTestCampaign({ payload }) {
  try {
    const response = yield call(testEmailCampaignRequest, payload);
    yield put(testEmailCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(testEmailCampaignError(errorMsg));
  }
}

const testPushCampaignRequest = async (testData) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}campaigns/test_mobile_push_campaign`,
    testData,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* testPushCampaign({ payload }) {
  try {
    const response = yield call(testPushCampaignRequest, payload);
    yield put(testPushCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(testPushCampaignError(errorMsg));
  }
}
const userCountFilterRequest = async (filters) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}segments/get_user_count_by_filter`,
    filters,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

const smsCampaignSaveAsDraftRequest = async ({ payload, id }) => {
  const method = id ? 'put' : 'post';
  const query = id ? `campaigns/${id}/draft` : 'campaigns/draft';
  return axios[method](`${apiUrlNewV3()}${query}`, payload, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* smsCampaignSaveAsDraft({ payload }) {
  try {
    let segmentIdResponse = '';
    if (!payload?.payload?.segment?.segment_filter_id) {
      segmentIdResponse = yield call(
        userCountFilterRequest,
        payload.payload.segment_filters
      );
    }
    if (segmentIdResponse) {
      (payload.payload.segment.segment_filter_id =
        segmentIdResponse.segment_filter.id),
        delete payload.payload.segment_filters;
    }
    const response = yield call(smsCampaignSaveAsDraftRequest, payload);
    yield put(smsCampaignSaveAsDraftSuccess({ response, payload }));
    yield put(smsCampaignSaveAsDraftClean());
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(smsCampaignSaveAsDraftError(errorMsg));
    yield put(smsCampaignSaveAsDraftClean());
  }
}

const fetchTemplatesAsync = async () => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}email_templates/get_predefined_templates`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data.email_templates;
  });
};

function* fetchTemplatesSaga({ payload }) {
  try {
    const response = yield call(fetchTemplatesAsync, payload);
    yield put(fetchTemplatesSuccess(response));
  } catch (error) {
    yield put(fetchTemplatesFailure(error));
  }
}

const fetchEmailTemplatesAPI = async (page, perPage) => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}email_templates?page=${page}&per_page=${perPage}`,
    {
      headers: getHeaders(),
    }
  )
    .then((response) => response)
    .catch((error) => ({ error }));
};

function* fetchEmailTemplates(action) {
  const { page, perPage } = action.payload;
  const response = yield call(fetchEmailTemplatesAPI, page, perPage);
  if (!response.error) {
    yield put(fetchEmailTemplatesSuccess(response.data));
  } else {
    yield put(fetchEmailTemplatesFailure(response.error));
  }
}

const getEmailRequest = async (channelEmailId) => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}email_general_settings/${channelEmailId}`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* getEmailAddress({ payload }) {
  try {
    const response = yield call(getEmailRequest, payload);
    yield put(getEmailAddressSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const addNewTemplateAsync = async (formData) => {
  const method = formData.id ? 'put' : 'post';
  const endPoint = formData.id
    ? `email_templates/${formData.id}`
    : 'email_templates';
  return axios[method](`${apiUrlNewV3()}${endPoint}`, formData, {
    headers: getHeaders(),
  }).then((response) => {
    return response.data;
  });
};

function* addNewTemplate({ payload }) {
  try {
    const response = yield call(addNewTemplateAsync, payload);
    const responseData = response?.email_template || response;
    yield put(addEmailTemplateSuccess({ ...responseData, success: true }));
  } catch (error) {
    if (error?.response?.data && Array.isArray(error.response.data)) {
      yield put(
        addEmailTemplateFailure({
          error: error.response.data,
          payload: payload,
        })
      );
    } else {
      const apiError = commonRequestException(error);
      yield put(
        addEmailTemplateFailure({
          error: [apiError.errorMsg],
          payload: payload,
        })
      );
    }
  }
}

const deleteEmailTemplateRequest = async (template) => {
  const method = 'delete';
  return axios[method](`${apiUrlNewV3()}email_templates/${template.id}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* deleteEmailTemplate({ payload }) {
  try {
    const response = yield call(deleteEmailTemplateRequest, payload);
    yield put(deleteEmailTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteEmailCampaignTemplateError(errorMsg));
  }
}

const fetchEmailTemplateByIdApi = async (emailTemplateId) => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}email_templates/${emailTemplateId}`, {
    headers: getHeaders(),
  })
    .then((response) => response)
    .catch((error) => ({ error }));
};

function* fetchEmailTemplateById({ payload }) {
  try {
    const response = yield call(fetchEmailTemplateByIdApi, payload);
    yield put(addEmailTemplateSuccess(response?.data?.email_template));
  } catch (error) {
    if (error?.response?.data && Array.isArray(error.response.data)) {
      yield put(addEmailTemplateFailure(error.response.data));
    } else {
      commonRequestException(error);
    }
  }
}

// WhatsApp Template API call

const getWATemplatesListAsync = async (payload) => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}channels/${payload.connectorId}/whatsapp_templates`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* getWhatsAppCampaignTemplatesList({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getWATemplatesListAsync, payload);
    yield put(getWhatsAppCampaignTemplatesListSuccess(response, payload));
    yield put(getWhatsAppCampaignTemplatesListDetailed(response, payload));
  } catch (error) {
    commonRequestException(error);
  }
}

//  WhatsApp Test Campaign API

const testWhatsAppCampaignRequest = async (testData) => {
  const method = 'post';
  return axios[method](`${apiUrlNewV3()}campaigns/whatsapp/test`, testData, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* whatsAppTestCampaign({ payload }) {
  try {
    const response = yield call(testWhatsAppCampaignRequest, payload);
    yield put(testWhatsAppCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(testWhatsAppCampaignError(errorMsg));
  }
}

const getCampaignInfoRequest = async (payload) => {
  let query = '';
  if (payload?.type === 'Info') {
    query = `/${payload.campaignID}`;
  }
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}campaigns${query}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getCampaignInfoData({ payload }) {
  try {
    const response = yield call(getCampaignInfoRequest, payload);
    yield put(getCampaignInfoByIdSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const getInAppTemplateListRequest = async ({ payload }) => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}in_app_templates`, {
    headers: getHeaders(),
    params: payload,
  }).then((res) => {
    return res.data;
  });
};

function* getInAppTemplatesList({ payload }) {
  const requestParams = {
    page:
      payload?.currentPage ??
      ContentConfigurationEnums.SAVE_TEMPLATES_DEFAULT_PAGE,
    per_page: ContentConfigurationEnums.INAPP.SAVED_TEMPLATES_PER_PAGE,
    target_platform: payload?.target_platform
      ? payload?.target_platform.toString().toLowerCase()
      : '',
    search_term: payload?.searchQuery,
  };
  try {
    const response = yield call(getInAppTemplateListRequest, {
      payload: requestParams,
    });
    yield put(getInAppTemplateListSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(setInAppListError(errorMsg));
  }
}

const saveInappNewTemplateAsync = async (payload) => {
  const method = payload.id ? 'put' : 'post';
  const endPoint = payload.id
    ? `in_app_templates/${payload.id}`
    : 'in_app_templates';
  return axios[method](`${apiUrlNewV3()}${endPoint}`, payload, {
    headers: getHeaders(),
  }).then((response) => {
    return response.data;
  });
};

function* saveInappNewTemplate({ payload }) {
  try {
    const response = yield call(saveInappNewTemplateAsync, payload);
    yield put(saveInappTemplateSuccess(response?.inapp_template || response));
  } catch (error) {
    if (error?.response?.data && Array.isArray(error.response.data)) {
      yield put(
        saveInappTemplateFailure({
          error: error.response.data,
          payload: payload,
        })
      );
    } else {
      const apiError = commonRequestException(error);
      yield put(
        saveInappTemplateFailure({
          error: [apiError.errorMsg],
          payload: payload,
        })
      );
    }
  }
}

const updateExistingInappTemplateAsync = async (payload) => {
  const inappId = payload?.in_app?.id;
  if (!inappId) {
    throw new Error(ContentConfigurationEnums.INAPP_ID);
  }

  const endPoint = `in_app_templates/${inappId}`;

  const response = await axios.patch(`${apiUrlNewV3()}${endPoint}`, payload, {
    headers: getHeaders(),
  });

  return response.data;
};

function* updateExistingInappTemplate({ payload }) {
  try {
    const response = yield call(updateExistingInappTemplateAsync, payload);
    yield put(updateInappTemplateSuccess(response));
  } catch (error) {
    if (error?.response?.data && Array.isArray(error.response.data)) {
      yield put(
        updateInappTemplateFailure({
          error: error.response.data,
          payload: payload,
        })
      );
    } else {
      const apiError = commonRequestException(error);
      yield put(
        saveInappTemplateFailure({
          error: [apiError.errorMsg],
          payload: payload,
        })
      );
    }
  }
}

const deleteInappTemplateRequest = async (template) => {
  const method = 'delete';
  return axios[method](`${apiUrlNewV3()}in_app_templates/${template.id}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* deleteInappTemplate({ payload }) {
  try {
    const response = yield call(deleteInappTemplateRequest, payload);
    yield put(deleteInappTemplateSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteInappTemplateError(errorMsg));
  }
}

const getBestTimeForUsersAsync = async (payload) => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}bts?channel=${payload.channelType}&${
      payload.segmentId
        ? `segment_id=${payload.segmentId}`
        : `segment_filter_id=${payload.segmentFilterId}`
    }  `,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* getBestTimeForUsers({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getBestTimeForUsersAsync, payload);
    yield put(getBestTimeForUsersSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}
const checkCampaignNameRequest = async (params) => {
  const method = 'get';
  if (params.campaignID) {
    return axios[method](
      `${apiUrlNewV3()}campaigns/check_campaign_name_availability?title=${
        params.campaignName
      }&campaign_id=${params.campaignID}`,
      {
        headers: getHeaders(),
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        return errorMsg;
      });
  } else {
    return axios[method](
      `${apiUrlNewV3()}campaigns/check_campaign_name_availability?title=${
        params.campaignName
      }`,
      {
        headers: getHeaders(),
      }
    )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        return errorMsg;
      });
  }
};

function* checkCampaignName({ payload }) {
  try {
    const response = yield call(checkCampaignNameRequest, payload);
    yield put(checkCampaignNameSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(checkCampaignNameError(errorMsg));
  }
}

const testInappCampaignRequest = async (testData) => {
  const method = 'post';
  return axios[method](
    `${apiUrlNewV3()}campaigns/test_in_app_campaign`,
    testData,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* testInappCampaign({ payload }) {
  try {
    const response = yield call(testInappCampaignRequest, payload);
    yield put(testInappCampaignSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(testInappCampaignError(errorMsg));
  }
}

export function* watchGetCampaigns() {
  yield takeEvery(CAMPAIGNS_GET, getCampaigns);
  yield takeEvery(CAMPAIGNS_DELETE, deleteCampaign);
  yield takeEvery(CAMPAIGNS_ADD, addCampaign);
  yield takeEvery(CAMPAIGNS_CREATE_TYPE_ADD, addCampaignCreateType);
  yield takeEvery(SMS_CAMAPAIGNS_TEMPLATES_GET_ALL, getAllSmsTemplates);
  yield takeEvery(SMS_CAMAPAIGN_TEMPLATE_DELETE, deleteSmsTemplate);
  yield takeEvery(SMS_CAMAPAIGN_TEMPLATE_ADD, addSmsTemplateRequest);
  yield takeEvery(SMS_CAMAPAIGN_TEMPLATE_UPDATE, updateSmsTemplate);
  yield takeEvery(GET_PUSH_TEMPLATE, getAllTemplates);
  yield takeEvery(DELETE_PUSH_TEMPLATE, deletePushTemplate);
  yield takeEvery(SAVE_PUSH_TEMPLATE, addPushTemplateRequest);
  yield takeEvery(UPDATE_PUSH_TEMPLATE, updatePushTemplate);
  yield takeEvery(SMS_CAMAPAIGN_TAGS, getSmsCampaignsTags);
  yield takeEvery(SMS_CAMAPAIGNS_GET_ALL_SENDER_ID, getAllSenderId);
  yield takeEvery(
    SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID,
    getAllTemplateBasedOnSenderId
  );
  yield takeEvery(SCHEDULE_SMS_CAMPAIGN, scheduleSmsCampaign);
  yield takeEvery(
    SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID,
    getTemplateBasedOnTemplateId
  );
  yield takeEvery(SMS_CONTENT_GENERATE_TINY_URL, generateTinyUrl);
  yield takeEvery(PUSH_CONTENT_GENERATE_TINY_URL, generatePushTinyUrl);
  yield takeEvery(SMS_CONTENT_GENERATE_PERSONALIZED_MSG, getPersonalizedMsg);
  yield takeEvery(
    SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE,
    getPersonalizedMsgTitle
  );
  yield takeEvery(GENERATE_PERSONALIZED_PUSH_MSG, getPersonalizedPushMsg);
  yield takeEvery(TEST_SMS_CAMPAIGN, smsTestCampaign);
  yield takeEvery(TEST_PUSH_CAMPAIGN, testPushCampaign);
  yield takeEvery(TEST_INAPP_CAMPAIGN, testInappCampaign);
  yield takeEvery(TEST_EMAIL_CAMPAIGN, emailTestCampaign);
  yield takeEvery(SMS_CAMPAIGN_SAVE_AS_DRAFT, smsCampaignSaveAsDraft);
  yield takeEvery(FETCH_TEMPLATES_REQUEST, fetchTemplatesSaga);
  yield takeEvery(FETCH_EMAIL_TEMPLATES_REQUEST, fetchEmailTemplates);
  yield takeEvery(GET_EMAIL_ADDRESS, getEmailAddress);
  yield takeEvery(ADD_EMAIL_TEMPLATE, addNewTemplate);
  yield takeEvery(
    GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST,
    getWhatsAppCampaignTemplatesList
  );
  yield takeEvery(TEST_WHATSAPP_CAMPAIGN, whatsAppTestCampaign);
  yield takeEvery(GET_CAMPAIGN_INFO_BY_ID, getCampaignInfoData);
  yield takeEvery(GET_EMAIL_TEMPLATE_BY_ID, fetchEmailTemplateById);
  yield takeEvery(DELETE_EMAIL_CAMPAIGN_TEMPLATE, deleteEmailTemplate);

  //INAPP
  yield takeEvery(GET_INAPP_TEMPLATE, getInAppTemplatesList);
  yield takeEvery(SAVE_INAPP_TEMPLATE, saveInappNewTemplate);
  yield takeEvery(UPDATE_INAPP_TEMPLATE, updateExistingInappTemplate);
  yield takeEvery(DELETE_INAPP_TEMPLATE, deleteInappTemplate);
  yield takeEvery(GET_BEST_TIME_FOR_USERS, getBestTimeForUsers);
  yield takeEvery(CHECK_CAMPAIGN_NAME, checkCampaignName);
}

export default function* rootSaga() {
  yield all([fork(watchGetCampaigns)]);
}
