import {
  ACCOUNT_GET,
  ACCOUNT_GET_SUCCESS,
  ACCOUNTS_GET,
  ACCOUNTS_GET_SUCCESS,
  ACCOUNTS_DELETE,
  ACCOUNTS_DELETE_SUCCESS,
  ACCOUNTS_DELETE_ERROR,
  ACCOUNTS_DELETE_CLEAN,
  ACCOUNTS_ADD,
  ACCOUNTS_ADD_SUCCESS,
  ACCOUNTS_ADD_ERROR,
  ACCOUNTS_ADD_CLEAN,
  CUSTOM_DOMAIN_ADD,
  CUSTOM_DOMAIN_ADD_SUCCESS,
  CUSTOM_DOMAIN_REMOVE,
  CUSTOM_DOMAIN_ADD_ERROR,
  CUSTOM_DOMAIN_ADD_CLEAN,
  CUSTOM_DOMAIN_REMOVE_SUCCESS,
  CUSTOM_DOMAIN_REMOVE_CLEAN,
  CUSTOM_DOMAIN_REMOVE_ERROR,
  GET_APPID,
  GET_APPID_SUCCESS,
  GET_APPID_FAILED,
} from 'redux/constants';

export const getAccount = (userId) => ({
  type: ACCOUNT_GET,
  payload: userId,
});
export const getAccountSuccess = (items) => ({
  type: ACCOUNT_GET_SUCCESS,
  payload: items,
});

export const getAccounts = (userId) => ({
  type: ACCOUNTS_GET,
  payload: userId,
});
export const getAccountsSuccess = (items) => ({
  type: ACCOUNTS_GET_SUCCESS,
  payload: items,
});

export const deleteAccount = (item) => ({
  type: ACCOUNTS_DELETE,
  payload: item,
});

export const deleteAccountSuccess = (items) => ({
  type: ACCOUNTS_DELETE_SUCCESS,
  payload: items,
});

export const deleteAccountError = (error) => ({
  type: ACCOUNTS_DELETE_ERROR,
  payload: error,
});

export const deleteAccountClean = (item) => ({
  type: ACCOUNTS_DELETE_CLEAN,
  payload: item,
});

export const addAccount = (item) => ({
  type: ACCOUNTS_ADD,
  payload: item,
});

export const addAccountSuccess = (items) => ({
  type: ACCOUNTS_ADD_SUCCESS,
  payload: items,
});

export const addAccountError = (error) => ({
  type: ACCOUNTS_ADD_ERROR,
  payload: error,
});

export const addAccountClean = (item) => ({
  type: ACCOUNTS_ADD_CLEAN,
  payload: item,
});

export const addCustomDomain = (item) => ({
  type: CUSTOM_DOMAIN_ADD,
  payload: item,
});

export const addCustomDomainSuccess = (items) => ({
  type: CUSTOM_DOMAIN_ADD_SUCCESS,
  payload: items,
});

export const addCustomDomainError = (error) => ({
  type: CUSTOM_DOMAIN_ADD_ERROR,
  payload: error,
});

export const addCustomDomainClean = (item) => ({
  type: CUSTOM_DOMAIN_ADD_CLEAN,
  payload: item,
});

export const removeCustomDomain = (item) => ({
  type: CUSTOM_DOMAIN_REMOVE,
  payload: item,
});

export const removeCustomDomainSuccess = (items) => ({
  type: CUSTOM_DOMAIN_REMOVE_SUCCESS,
  payload: items,
});

export const removeCustomDomainError = (error) => ({
  type: CUSTOM_DOMAIN_REMOVE_ERROR,
  payload: error,
});

export const removeCustomDomainClean = (item) => ({
  type: CUSTOM_DOMAIN_REMOVE_CLEAN,
  payload: item,
});

export const getAppId = () => ({
  type: GET_APPID,
});
export const getAppIdSuccess = (item) => ({
  type: GET_APPID_SUCCESS,
  payload: item,
});
export const getAppIdFailure = () => ({
  type: GET_APPID_FAILED,
});
