import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import axios from 'axios';
import {
  getHeaders,
  commonRequestException,
  apiUrlNewV3,
  apiUrlNew,
} from 'helpers/ApiHelper';

import {
  GET_CONTROL_GROUPS,
  CREATE_CONTROL_GROUPS,
  DELETE_CONTROL_GROUPS,
  DOWNLOAD_CONTROL_GROUPS,
  ENABLE_CONTROL_GROUPS,
  GET_STATUS_GLOBAL_CONTROL_GROUP,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
  GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
  DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS,
} from 'redux/constants';

import {
  createControlGroupsSuccess,
  createControlFailure,
  getControlGroupsSuccess,
  downloadCreateControlGroupsSuccess,
  downloadCreateControlGroupsReset,
  deleteCreateControlGroupsSuccess,
  deleteCreateControlGroupsSuccessReset,
  enableControlGroupsSuccess,
  getStatusGlobalControlGroupSuccess,
  getCampaignsWithControlCount,
  // getCampaignControlGroupChannels,
  getCampaignControlGroupChannelsSuccess,
  // addCampaignControlGroupChannels,
  addCampaignControlGroupChannelsSuccess,
  addCampaignControlGroupChannelsFailure,
  addCampaignControlGroupChannelsReset,
  downloadSampleFileControlGroupSuccess,
  downloadSampleFileControlGroupReset,
} from './actions';

const enableControlGroupsAsync = async (param) => {
  let method = 'post';
  let postData = param;
  if (param && param !== '') {
    return axios[method](`${apiUrlNewV3()}enable_control_group`, postData, {
      headers: getHeaders(),
    }).then((res) => {
      return res.data;
    });
  } else {
    return axios[method](`${apiUrlNewV3()}enable_control_group`, '', {
      headers: getHeaders(),
    }).then((res) => {
      return res.data;
    });
  }
};

function* enableControlGroups({ payload }) {
  try {
    const response = yield call(enableControlGroupsAsync, payload);
    yield put(enableControlGroupsSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(createControlFailure(errorMsg));
  }
}

const getControlGroupsAsync = async (payload) => {
  let method = 'get';
  if (payload?.id) {
    return axios[method](
      `${apiUrlNewV3()}global_control_groups/${
        payload.id
      }?global_control_group[active]=${payload?.param?.toggle_status}`,
      {
        headers: getHeaders(),
      }
    ).then((res) => {
      return res.data;
    });
  } else {
    return axios[method](`${apiUrlNewV3()}global_control_groups`, {
      headers: getHeaders(),
    }).then((res) => {
      return res.data;
    });
  }
};

function* getControlGroups({ payload }) {
  try {
    const response = yield call(getControlGroupsAsync, payload);
    if (payload?.id) {
      yield put(getCampaignsWithControlCount(response));
    } else {
      yield put(getControlGroupsSuccess(response));
    }
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(createControlFailure(errorMsg));
  }
}

const getCampaignControlGroupChannelsAsync = async () => {
  let method = 'get';
  return axios[method](`${apiUrlNewV3()}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getCampaignControlGroupChannels({ payload }) {
  try {
    const response = yield call(getCampaignControlGroupChannelsAsync, payload);
    yield put(getCampaignControlGroupChannelsSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(createControlFailure(errorMsg));
  }
}

const addCampaignControlGroupChannelsAsync = async (param) => {
  let method = 'put';
  let postData = param.payload;

  return axios[method](`${apiUrlNewV3()}`, postData, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* addCampaignControlGroupChannels(payload) {
  try {
    const response = yield call(addCampaignControlGroupChannelsAsync, payload);
    yield put(addCampaignControlGroupChannelsSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(addCampaignControlGroupChannelsFailure(errorMsg));
  }
}

const getStatusGlobalControlGroupAsync = async (param) => {
  let method = 'get';
  return axios[method](`${apiUrlNewV3()}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getStatusGlobalControlGroup({ payload }) {
  try {
    const response = yield call(getStatusGlobalControlGroupAsync, payload);
    yield put(getStatusGlobalControlGroupSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(createControlFailure(errorMsg));
  }
}

const getCreateControlGroupsAsync = async (param) => {
  let method = 'put';
  let postData = param.payload;
  if (postData?.active === false) {
    return axios[method](
      `${apiUrlNewV3()}global_control_groups/${postData.groupID}`,
      { active: postData?.active },
      {
        headers: getHeaders(),
      }
    ).then((res) => {
      return res.data;
    });
  } else {
    return axios[method](
      `${apiUrlNewV3()}global_control_groups/${param.groupID}`,
      postData,
      {
        headers: getHeaders(),
      }
    ).then((res) => {
      return res.data;
    });
  }
};

function* createControl({ payload }) {
  try {
    const response = yield call(getCreateControlGroupsAsync, payload);
    yield put(createControlGroupsSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(createControlFailure(errorMsg));
  }
}

const getresetControlGroupsAsync = async (param) => {
  let method = 'put';
  return axios[method](
    `${apiUrlNewV3()}global_control_groups/${param}/reset`,
    '',
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* resetControlGroups({ payload }) {
  try {
    const response = yield call(getresetControlGroupsAsync, payload);
    yield put(deleteCreateControlGroupsSuccess(response));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteCreateControlGroupsSuccessReset(errorMsg));
  }
}

const getDownloadGroupsAsync = async (param) => {
  let method = 'get';
  return axios[method](
    `${apiUrlNewV3()}global_control_groups/${param.id}/download_users_csv_file`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    const data = res.data;
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `ControlGroups_${param.id}_${param.created_at}`;
    a.click();
    return res.data;
  });
};

function* downloadControlGroups({ payload }) {
  try {
    const response = yield call(getDownloadGroupsAsync, payload);
    yield put(downloadCreateControlGroupsSuccess());
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(downloadCreateControlGroupsReset(errorMsg));
  }
}

const getDownloadSampleFileGroupsAsync = async (param) => {
  let method = 'get';
  return axios[method](
    `${apiUrlNewV3()}global_control_groups/download_sample_csv_file`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    const data = res.data;
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `control-groups-sample-file`;
    a.click();
    return res.data;
  });
};

function* downloadSampleFileControlGroup({ payload }) {
  try {
    const response = yield call(getDownloadSampleFileGroupsAsync, payload);
    yield put(downloadSampleFileControlGroupSuccess());
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(downloadSampleFileControlGroupReset(errorMsg));
  }
}

export function* watchGetControlGroups() {
  yield takeEvery(ENABLE_CONTROL_GROUPS, enableControlGroups);
  yield takeEvery(GET_CONTROL_GROUPS, getControlGroups);
  yield takeEvery(GET_STATUS_GLOBAL_CONTROL_GROUP, getStatusGlobalControlGroup);
  yield takeEvery(
    GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
    getCampaignControlGroupChannels
  );
  yield takeEvery(
    ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
    addCampaignControlGroupChannels
  );
  yield takeEvery(CREATE_CONTROL_GROUPS, createControl);
  yield takeEvery(DELETE_CONTROL_GROUPS, resetControlGroups);
  yield takeEvery(DOWNLOAD_CONTROL_GROUPS, downloadControlGroups);
  yield takeEvery(
    DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS,
    downloadSampleFileControlGroup
  );
}

export default function* rootSaga() {
  yield all([fork(watchGetControlGroups)]);
}
