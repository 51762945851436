import ContentConfigurationEnums from 'enums/campaigns/ContentConfigurationEnums';
import ScheduleAndGoalsEnums from 'enums/campaigns/scheduleAndGoalsEnums';
import createSegementEnums from 'enums/createSegment/createSegementEnums';
import CommonEnums from 'enums/commonEnums';
import moment from 'moment';
import moment_timeZone from 'moment-timezone';
import {
  DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY,
  TIME_FORMAT_WITHOUT_DATE,
} from 'constants/appConstant';
import TargetAudienceEnums from 'enums/campaigns/targetAudienceEnums';

export function getSendCampaignTimeValue(form, campaignType) {
  let label = '';
  if (
    form.values[campaignType].sendCampaignType ===
    ScheduleAndGoalsEnums.IMMEDIATELY
  ) {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_AT_IMMEDIATELY;
  } else if (
    form.values[campaignType]?.sendCampaignTime ===
    ScheduleAndGoalsEnums.AT_FIXED_TIME
  ) {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_AT_FIXED_TIME;
  } else if (
    form.values[campaignType]?.sendCampaignTime ===
    ScheduleAndGoalsEnums.SEND_IN_USER_TIME_ZONE
  ) {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_SEND_IN_USER_TIME_ZONE;
  } else {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_BEST_TIME_FOR_USER;
  }
  return label;
}

export function getSendCampaignTimeValueBasedOnEvent(value) {
  let label = '';
  if (value === ScheduleAndGoalsEnums.AT_FIXED_TIME) {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_AT_FIXED_TIME;
  } else if (value === ScheduleAndGoalsEnums.SEND_IN_USER_TIME_ZONE) {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_SEND_IN_USER_TIME_ZONE;
  } else {
    label = ScheduleAndGoalsEnums.OBJECT_KEY_BEST_TIME_FOR_USER;
  }
  return label;
}

export function getOcurrenceType(form, campaignType) {
  let ocurrenceType = '';
  if (
    form.values[campaignType].sendCampaignType === ScheduleAndGoalsEnums.DAILY
  ) {
    ocurrenceType = ScheduleAndGoalsEnums.DAY;
  } else if (
    form.values[campaignType].sendCampaignType === ScheduleAndGoalsEnums.WEEKLY
  ) {
    ocurrenceType = ScheduleAndGoalsEnums.WEEK;
  } else {
    ocurrenceType = ScheduleAndGoalsEnums.MONTH;
  }
  return ocurrenceType;
}

export function getEndsFieldType(form, campaignType) {
  let field = '';
  if (
    form?.values[campaignType]?.[getSendCampaignTimeValue(form, campaignType)]
      ?.ends === ScheduleAndGoalsEnums.ON
  ) {
    field = ScheduleAndGoalsEnums.OBJECT_KEY_On;
  } else if (
    form?.values[campaignType]?.[getSendCampaignTimeValue(form, campaignType)]
      ?.ends === ScheduleAndGoalsEnums.AFTER
  ) {
    field = ScheduleAndGoalsEnums.OBJECT_KEY_After;
  } else {
    field = ScheduleAndGoalsEnums.NEVER;
  }
  return field;
}

export function getSendType(values, campaignType) {
  let sendCampaignType = '';
  if (
    values[campaignType].sendCampaignTime ===
    ScheduleAndGoalsEnums.AT_FIXED_TIME
  ) {
    sendCampaignType = ScheduleAndGoalsEnums.OBJECT_KEY_AT_FIXED_TIME;
  } else if (
    values[campaignType].sendCampaignTime ===
    ScheduleAndGoalsEnums.BEST_TIME_FOR_USER
  ) {
    sendCampaignType = ScheduleAndGoalsEnums.OBJECT_KEY_BEST_TIME_FOR_USER;
  } else {
    sendCampaignType = ScheduleAndGoalsEnums.OBJECT_KEY_SEND_IN_USER_TIME_ZONE;
  }
  return sendCampaignType;
}

export function getConvertedStringWithHyphen(text) {
  return text.replace(/_/g, '-').replace(/\b\w/g, (c) => c.toUpperCase());
}
export function getConvertedStringWithSpace(text) {
  return text
    ? text.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
    : '';
}

export function previewTinyUrl(
  tinyUrlConversionIsChecked,
  msg,
  convertedTinyUrls
) {
  let convertedMessage = msg;
  if (tinyUrlConversionIsChecked && Array.isArray(convertedTinyUrls)) {
    convertedTinyUrls.forEach((convertedUrl) => {
      convertedMessage = convertedMessage?.replace(
        convertedUrl.initialURL,
        convertedUrl.tinyUrl
      );
    });
  }
  return convertedMessage;
}

export function checkPersonalizeMsg(
  form,
  message,
  setPersoalizeFieldsArray,
  toggleModal,
  channelType = CommonEnums.SMS,
  msgBody = []
) {
  if (channelType === CommonEnums.WHATSAPP) {
    const fileds = msgBody.map((bodyType) => {
      const regex = /\{\{([^{}]+)\}\}/g;
      const personalizeFields = bodyType?.text?.match(regex);
      return personalizeFields?.length
        ? personalizeFields?.map((field) => {
            return {
              key: field.replace('{', '').replace('}', ''),
              type: bodyType.type.toLowerCase(),
              value: '',
              isAccepted: true,
              text: bodyType?.text,
              ...(bodyType?.type.includes(
                ContentConfigurationEnums.WHATSAPP_BUTTONS
              ) && { index: bodyType?.index, data: bodyType?.data }),
            };
          })
        : [];
    });
    form.setFieldValue('personalisedParam', {
      fieldsArray: fileds.flat(),
    });
    toggleModal(true);
  } else {
    const regex = /\{\{([^{}]+)\}\}/g;
    const personalizeFields = message.match(regex);
    if (personalizeFields?.length) {
      let allFields = [];
      setPersoalizeFieldsArray(personalizeFields);
      allFields = personalizeFields.map((field) => {
        return {
          key: field.replace('{', '').replace('}', ''),
          value: '',
          isAccepted: true,
        };
      });
      form.setFieldValue('personalisedParam', {
        fieldsArray: allFields,
      });
      toggleModal(true);
    }
  }
}

export function convertTinyUrl(msg, getTinyUrl) {
  let urlArray = [];

  const regex =
    /(https?:\/\/)?(www\.)?[a-zA-Z][a-zA-Z0-9-]*\.[a-zA-Z0-9-.]+(\/[^#\s]*)?(#[^\s]*)?/g;
  const matches = msg.match(regex);
  urlArray = matches ? matches.map((match) => match.trim()) : [];
  getTinyUrl(urlArray);
}

export function convertPushTinyUrl(msg, getTinyUrl, componentType) {
  let urlArray = [];
  const regex = ContentConfigurationEnums.REGEX_TINY;
  const matches = msg.match(regex);
  urlArray = matches
    ? matches.map((match) => {
        return {
          url: match.trim(),
          componentType: componentType,
        };
      })
    : [];
  getTinyUrl(urlArray);
}

export function getNumericValues(numericValue, initialValEmpty) {
  const options = [{ id: '', value: '', disabled: true }];
  for (let i = 1; i <= numericValue; i += 1) {
    options.push({ id: i, value: i });
  }
  if (initialValEmpty) {
    options.shift();
  }

  return options;
}

export function getCurrentGMTTimeZone(timeZoneString) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timeZoneString,
    timeZoneName: 'shortOffset',
  });
  return `(${formatter.format().slice(12, 21)})`;
}

export function getWhatsAppMessageFormat(component) {
  let constructMessage = '';
  if (component) {
    const header =
      component.find(
        (e) => e.type === ContentConfigurationEnums.WHATSAPP_HEADER
      ) ?? '';
    const body =
      component.find(
        (e) => e.type === ContentConfigurationEnums.WHATSAPP_BODY
      ) ?? '';
    const footer =
      component.find(
        (e) => e.type === ContentConfigurationEnums.WHATSAPP_FOOTER
      ) ?? '';
    constructMessage = `${header.text ?? ''} \n ${body.text ?? ''} \n ${
      footer.text ?? ''
    } `;
  }
  return constructMessage;
}

export function getColAttributesDropDownValues(colAttributesList) {
  const arr = [];
  Object.keys(colAttributesList).map((data) => {
    return arr.push({
      id: data,
      label: colAttributesList[data].name,
      value: data,
      isdisabled: false,
      type:
        colAttributesList[data].type !== ContentConfigurationEnums.INTEGER
          ? colAttributesList[data].type
          : ContentConfigurationEnums.NUMBER,
    });
  });
  return arr;
}

export const isFiltersValidForCampaign = (errors) => {
  return [
    createSegementEnums.INITIALVALUES.INCLUDED_FILTERS,
    createSegementEnums.INITIALVALUES.EXCLUDED_FILTERS,
  ].some((filter) => Object.keys(errors).includes(filter));
};

export function validateStartEndDate(startDate, endDate) {
  if (startDate && endDate) {
    return moment(startDate).unix() <= moment(endDate).unix();
  } else {
    return false;
  }
}

export function validateStartEndTime(startTime, endTime, startDate, endDate) {
  if (startTime && endTime) {
    const sendTime = new Date(`${startDate} ${startTime}`);
    const endTimeSelected = new Date(`${endDate} ${endTime}`);
    return moment(sendTime).unix() <= moment(endTimeSelected).unix();
  } else {
    return false;
  }
}

export function validateSelectedAndCurrentDateTimeLocal(date, time) {
  const current = new Date(moment().format('ddd MMM D YYYY HH:mm'));
  const selected = new Date(`${date} ${time}`);
  return moment(current).unix() <= moment(selected).unix();
}

export function validateSelectedAndCurrentDateTime(value, schema) {
  let time =
    schema.from[schema.from.length - 1]?.value?.location_trigger?.time_bound_list;
  const localTime = moment_timeZone
    .tz(
      `${schema.parent.startDate} ${value}`,
      CommonEnums.DATE_TIME_FORMAT,
      schema.from[schema.from.length - 1]?.value?.campaignTimeZone
    )
    .toDate();
  const selected = Math.floor(localTime.getTime() / 1000);
  if (time?.length > 1 && schema.options.index != 0) {
    let t = moment_timeZone
      .tz(
        `${time[time.length - 2]['endDate']} ${
          time[time.length - 2]['endTime']
        }`,
        CommonEnums.DATE_TIME_FORMAT,
        time[time.length - 1]['campaign_time_zone']
      )
      .toDate();
    let m = Math.floor((t.getTime() + 1 * 60 * 1000) / 1000);
    return m <= selected;
  } else {
    const current = moment_timeZone()
      .tz(schema.from[2]?.value?.campaignTimeZone)
      ?.unix();
    return current <= selected;
  }
}

export function validateSelectedAndCurrentDateTimeForEventTrigger(
  value,
  schema
) {
  const localTime = moment_timeZone
    .tz(
      `${schema.parent.startDate} ${value}`,
      CommonEnums.DATE_TIME_FORMAT,
      schema.from[schema.from.length - 1]?.value?.campaignTimeZone
    )
    .toDate();

  const selected = Math.floor(localTime.getTime() / 1000);

  const current = moment_timeZone()
    .tz(schema.from[1]?.value?.campaignTimeZone)
    ?.unix();
  return current <= selected;
}

export function validateSelectedAndScheduleDateTime(
  date,
  time,
  scheduleDate,
  scheduleTime,
  schema
) {
  const scheduleTimezone = moment_timeZone
    .tz(
      `${scheduleDate} ${scheduleTime}`,
      CommonEnums.DATE_TIME_FORMAT,
      schema.from[schema.from.length - 1]?.value?.campaignTimeZone
    )
    .toDate();

  const schedule = Math.floor(scheduleTimezone.getTime() / 1000);
  const selectedTimezone = moment_timeZone
    .tz(
      `${date} ${time}`,
      CommonEnums.DATE_TIME_FORMAT,
      schema.from[schema.from.length - 1]?.value?.campaignTimeZone
    )
    .toDate();
  const selected = Math.floor(selectedTimezone.getTime() / 1000);
  return schedule <= selected;
}
export function checkType(link) {
  if (
    link?.endsWith('.png') ||
    link?.endsWith('.jpg') ||
    link?.endsWith('.jpeg')
  ) {
    return 'image';
  } else if (link?.endsWith('.pdf')) {
    return 'document';
  } else {
    return 'video';
  }
}

export function buttonTypeFlows(buttonList) {
  const flowBtnList = buttonList.filter(
    (btn) => btn.type === ContentConfigurationEnums.WHATSAPP_FLOW_BUTTONS
  );

  if (flowBtnList?.length > 0) {
    return true;
  } else {
    return false;
  }
}

export function mapFormValuesToPayload(obj) {
  return {
    upload_image: obj?.upload_image?.toString() ?? 'true',
    image_url_value: obj?.imageUrlValue ?? undefined,
    image: obj?.image ?? '',
    message: obj?.message ?? '',
    message_summary: obj?.messageSummary ?? '',
    message_title: obj?.messageTitle ?? '',
    notification_channel: obj?.notificationChannel ?? 'general',
    personalise_message: obj?.personalize ?? false,
    tiny_url_conversion: obj?.tinyUrlConversion ?? false,
    personalise_title: obj?.titlePersonalize ?? false,
    sub_title: obj?.subTitle ?? '',
    tiny_urls: obj?.tiny_urls ?? [],
    big_urls: obj?.big_urls ?? [],
    personalise_title_mapping_attribute:
      obj?.personalise_title_mapping_attribute ?? {},
    personalise_mapping_attribute: obj?.personalise_mapping_attribute ?? {},
  };
}

export function updatePushChampaignValuesToForm(obj) {
  return {
    upload_image: obj?.upload_image?.toString() ?? 'true',
    imageUrlValue: obj?.image_url_value ?? '',
    image: obj?.image ?? '',
    message: obj?.message ?? '',
    messageSummary: obj?.message_summary ?? '',
    messageTitle: obj?.message_title ?? '',
    notificationChannel: obj?.notification_channel ?? 'general',
    personalize: obj?.personalise_message ?? false,
    tinyUrlConversion: obj?.tiny_url_conversion ?? false,
    titlePersonalize: obj?.personalise_title ?? false,
    subTitle: obj?.sub_title ?? '',
    tiny_urls: obj?.tiny_urls ?? [],
    big_urls: obj?.big_urls ?? [],
    personalise_title_mapping_attribute:
      obj?.personalise_title_mapping_attribute ?? {},
    personalise_mapping_attribute: obj?.personalise_mapping_attribute ?? {},
  };
}

export const updateTime = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export function mapDraftDataToScheduleAndGoalsOneTime(scheudleAndGoals) {
  const atSpefificTime =
    scheudleAndGoals?.schedule_type === ScheduleAndGoalsEnums.AT_SPECIFIC_TIME;
  const asSoonAsPossible =
    scheudleAndGoals?.schedule_type ===
    ScheduleAndGoalsEnums.AS_SOON_AS_POSSIBLE;
  const campaignTimeZone =
    scheudleAndGoals.campaign_time_zone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    one_time: {
      sendCampaignType: atSpefificTime
        ? ScheduleAndGoalsEnums.AT_SPECIFIC_DATE_AND_TIME
        : ScheduleAndGoalsEnums.IMMEDIATELY,
      sendCampaignTime:
        scheudleAndGoals?.send_campaign_time ??
        ScheduleAndGoalsEnums.AT_FIXED_TIME,
      immediately: {
        ends: scheudleAndGoals?.end_date
          ? ScheduleAndGoalsEnums.ON
          : ScheduleAndGoalsEnums.NEVER,
        on: {
          endDate:
            asSoonAsPossible && scheudleAndGoals?.end_date
              ? moment_timeZone
                  .unix(scheudleAndGoals.end_date)
                  .tz(campaignTimeZone)
                  ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
              : moment_timeZone
                  .tz(campaignTimeZone)
                  ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
          endTime:
            asSoonAsPossible && scheudleAndGoals?.end_date
              ? moment_timeZone
                  .unix(scheudleAndGoals.end_date)
                  .tz(campaignTimeZone)
                  ?.format(TIME_FORMAT_WITHOUT_DATE)
              : moment_timeZone
                  .tz(campaignTimeZone)
                  ?.format(TIME_FORMAT_WITHOUT_DATE),
        },
      },
      atFixedTime: {
        startDate:
          atSpefificTime && scheudleAndGoals?.start_date
            ? moment_timeZone
                .unix(scheudleAndGoals.start_date)
                .tz(campaignTimeZone)
                ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
            : moment_timeZone
                .tz(campaignTimeZone)
                ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
        sendTime:
          atSpefificTime && scheudleAndGoals?.start_date
            ? moment_timeZone
                .unix(scheudleAndGoals.start_date)
                .tz(campaignTimeZone)
                .format(TIME_FORMAT_WITHOUT_DATE)
            : moment_timeZone
                .tz(campaignTimeZone)
                .format(TIME_FORMAT_WITHOUT_DATE),
        ends: scheudleAndGoals?.end_date
          ? ScheduleAndGoalsEnums.ON
          : ScheduleAndGoalsEnums.NEVER,
        on: {
          endDate:
            atSpefificTime && scheudleAndGoals?.end_date
              ? moment_timeZone
                  .unix(scheudleAndGoals.end_date)
                  .tz(campaignTimeZone)
                  ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
              : moment_timeZone
                  .tz(campaignTimeZone)
                  ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
          endTime:
            atSpefificTime && scheudleAndGoals?.end_date
              ? moment_timeZone
                  .unix(scheudleAndGoals.end_date)
                  .tz(campaignTimeZone)
                  ?.format(TIME_FORMAT_WITHOUT_DATE)
              : moment_timeZone
                  .tz(campaignTimeZone)
                  ?.format(TIME_FORMAT_WITHOUT_DATE),
        },
      },
      bestTimeForUser: {
        startDate: scheudleAndGoals?.start_date
          ? moment_timeZone
              .unix(scheudleAndGoals.start_date)
              .tz(campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
        sendTime: scheudleAndGoals?.start_date
          ? moment_timeZone
              .unix(scheudleAndGoals.start_date)
              .tz(campaignTimeZone)
              .format(TIME_FORMAT_WITHOUT_DATE)
          : moment_timeZone
              .tz(campaignTimeZone)
              .format(TIME_FORMAT_WITHOUT_DATE),
        sendMessagesBasedOnBestTime:
          scheudleAndGoals?.send_message_on_best_time_till
            ? moment_timeZone
                .unix(scheudleAndGoals.send_message_on_best_time_till)
                .tz(campaignTimeZone)
                .format(TIME_FORMAT_WITHOUT_DATE)
            : moment_timeZone
                .tz(campaignTimeZone)
                .format(TIME_FORMAT_WITHOUT_DATE),
        userBestTimeOutsideTimeWindow:
          scheudleAndGoals?.best_time_outside_window,
        userBestTimeNotAvailable: scheudleAndGoals?.best_time_not_available,
      },
      sendInUserTimeZone: {
        startDate: scheudleAndGoals?.start_date
          ? moment_timeZone
              .unix(scheudleAndGoals.start_date, CommonEnums.GMT)
              .utc()
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(CommonEnums.GMT)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
        sendTime: scheudleAndGoals?.start_date
          ? moment_timeZone
              .unix(scheudleAndGoals.start_date, CommonEnums.GMT)
              .utc()
              .format(TIME_FORMAT_WITHOUT_DATE)
          : moment_timeZone
              .tz(CommonEnums.GMT)
              .format(TIME_FORMAT_WITHOUT_DATE),
        sendUserTimeZonePassed:
          scheudleAndGoals?.send_if_user_timezone_expired?.toString() ??
          ScheduleAndGoalsEnums.YES_SEND_IT,
      },
    },
  };
}

export function mapDraftDataToScheduleAndGoalsPeriodic(scheudleAndGoals) {
  const campaignTimeZone =
    scheudleAndGoals.campaign_time_zone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const campaignEndType = scheudleAndGoals?.occurrences
    ? ScheduleAndGoalsEnums.AFTER
    : scheudleAndGoals?.end_date
    ? ScheduleAndGoalsEnums.ON
    : ScheduleAndGoalsEnums.NEVER;

  const repeatOnType =
    scheudleAndGoals?.repeat_on_day_of_week?.length > 0
      ? ScheduleAndGoalsEnums.DAY_OF_WEEK
      : ScheduleAndGoalsEnums.DAY_OF_MONTH;
  return {
    sendCampaignType:
      scheudleAndGoals?.scheduling_frequency ?? ScheduleAndGoalsEnums.DAILY,
    sendCampaignTime:
      scheudleAndGoals?.send_campaign_time ??
      ScheduleAndGoalsEnums.AT_FIXED_TIME,
    sendInUserTimeZone: {
      startDate: scheudleAndGoals?.start_date
        ? moment_timeZone
            .unix(scheudleAndGoals.start_date)
            .tz(campaignTimeZone)
            .utc()
            .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
        : moment_timeZone
            .tz(campaignTimeZone)
            .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      sendTime: scheudleAndGoals?.start_date
        ? moment_timeZone
            .unix(scheudleAndGoals.start_date)
            .tz(campaignTimeZone)
            .utc()
            .format(TIME_FORMAT_WITHOUT_DATE)
        : moment_timeZone.tz(campaignTimeZone).format(TIME_FORMAT_WITHOUT_DATE),
      sendUserTimeZonePassed:
        scheudleAndGoals?.send_if_user_timezone_expired?.toString() ??
        ScheduleAndGoalsEnums.YES_SEND_IT,
      ends: campaignEndType ?? '',
      on: {
        endDate: scheudleAndGoals?.end_date
          ? moment_timeZone
              .unix(scheudleAndGoals.end_date)
              .tz(campaignTimeZone)
              .utc()
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(new Date(), campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      },
      after: {
        occurrences: scheudleAndGoals?.occurrences ?? 0,
      },
    },
    atFixedTime: {
      startDate: scheudleAndGoals?.start_date
        ? moment_timeZone
            .unix(scheudleAndGoals.start_date)
            .tz(campaignTimeZone)
            .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
        : moment_timeZone
            .tz(campaignTimeZone)
            .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      sendTime: scheudleAndGoals?.start_date
        ? moment_timeZone
            .unix(scheudleAndGoals.start_date)
            .tz(campaignTimeZone)
            .format(TIME_FORMAT_WITHOUT_DATE)
        : moment_timeZone.tz(campaignTimeZone).format(TIME_FORMAT_WITHOUT_DATE),
      ends: campaignEndType ?? '',
      on: {
        endDate: scheudleAndGoals?.end_date
          ? moment_timeZone
              .unix(scheudleAndGoals.end_date)
              .tz(campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(new Date(), campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      },
      after: {
        occurrences: scheudleAndGoals?.occurrences ?? 0,
      },
    },
    bestTimeForUser: {
      startDate: scheudleAndGoals?.start_date
        ? moment_timeZone
            .unix(scheudleAndGoals.start_date)
            .tz(campaignTimeZone)
            .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
        : moment_timeZone
            .tz(campaignTimeZone)
            .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      sendTime: scheudleAndGoals?.start_date
        ? moment_timeZone
            .unix(scheudleAndGoals.start_date)
            .tz(campaignTimeZone)
            .format(TIME_FORMAT_WITHOUT_DATE)
        : moment_timeZone.tz(campaignTimeZone).format(TIME_FORMAT_WITHOUT_DATE),
      sendMessagesBasedOnBestTime:
        scheudleAndGoals?.send_message_on_best_time_till
          ? moment_timeZone
              .unix(scheudleAndGoals.send_message_on_best_time_till)
              .tz(campaignTimeZone)
              .format(TIME_FORMAT_WITHOUT_DATE)
          : moment_timeZone
              .tz(campaignTimeZone)
              .format(TIME_FORMAT_WITHOUT_DATE),
      userBestTimeOutsideTimeWindow: scheudleAndGoals.best_time_outside_window,
      userBestTimeNotAvailable: scheudleAndGoals.best_time_not_available,
      ends: campaignEndType ?? '',
      on: {
        endDate: scheudleAndGoals?.end_date
          ? moment_timeZone
              .unix(scheudleAndGoals.end_date)
              .tz(campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(new Date(), campaignTimeZone)
              .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      },
      after: {
        occurrences: scheudleAndGoals?.occurrences ?? 0,
      },
    },
    repeatEvery: scheudleAndGoals?.repeat_every ?? '',
    repeatOn: repeatOnType ?? ScheduleAndGoalsEnums.DAY_OF_MONTH,
    sendUserTimeZonePassed:
      scheudleAndGoals?.send_if_user_timezone_expired?.toString() ?? '',
    daysOfMonth:
      scheudleAndGoals?.repeat_on_day_of_month?.map((item) => parseInt(item)) ??
      [],
    daysOfWeek: scheudleAndGoals?.repeat_on_day_of_week ?? [],
    windowStartTime:
      scheudleAndGoals.start_time ?? moment().format(TIME_FORMAT_WITHOUT_DATE),
    windowEndTime:
      scheudleAndGoals.end_time ?? moment().format(TIME_FORMAT_WITHOUT_DATE),
  };
}

export function mapDraftDataToScheduleAndGoalsEventTriggered(scheudleAndGoals) {
  const campaignTimeZone =
    scheudleAndGoals?.campaign_time_zone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    sendCampaignType: ScheduleAndGoalsEnums.ACTIVE_CONTINOUSLY,
    startDate: scheudleAndGoals?.start_date
      ? moment_timeZone
          .unix(scheudleAndGoals.start_date)
          .tz(campaignTimeZone)
          .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
      : moment_timeZone
          .tz(new Date(), campaignTimeZone)
          .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
    sendTime: scheudleAndGoals?.start_date
      ? moment_timeZone
          .unix(scheudleAndGoals.start_date)
          .tz(campaignTimeZone)
          .format(TIME_FORMAT_WITHOUT_DATE)
      : moment_timeZone.tz(campaignTimeZone).format(TIME_FORMAT_WITHOUT_DATE),
    endDate: scheudleAndGoals?.end_date
      ? moment_timeZone
          .unix(scheudleAndGoals.end_date)
          .tz(campaignTimeZone)
          .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
      : moment_timeZone
          .tz(new Date(), campaignTimeZone)
          .format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
    endTime: scheudleAndGoals?.end_date
      ? moment_timeZone
          .unix(scheudleAndGoals.end_date)
          .tz(campaignTimeZone)
          .format(TIME_FORMAT_WITHOUT_DATE)
      : moment_timeZone
          .tz(new Date(), campaignTimeZone)
          .format(TIME_FORMAT_WITHOUT_DATE),
    trigger_relation: scheudleAndGoals?.trigger_relation,
    trigger_attr: scheudleAndGoals?.trigger_attr,
    time_value: scheudleAndGoals?.time_value,
    time_multiplier: scheudleAndGoals?.time_multiplier,
  };
}

export const priorityMap = {
  Critical: { value: '1', label: 'Critical', isDisabled: false },
  High: { value: '2', label: 'High', isDisabled: false },
  Medium: { value: '3', label: 'Medium', isDisabled: false },
  Normal: { value: '4', label: 'Normal', isDisabled: false },
  Low: { value: '5', label: 'Low', isDisabled: false },
};

export function mapDraftDataToScheduleAndGoals(
  scheduleAndGoals,
  campaignType,
  channelType
) {
  const payload = {
    ...(scheduleAndGoals?.campaign_time_zone && {
      campaignTimeZone:
        scheduleAndGoals.campaign_time_zone.split(' ').length > 1
          ? scheduleAndGoals.campaign_time_zone.split(' ')[1]
          : scheduleAndGoals.campaign_time_zone
          ? scheduleAndGoals.campaign_time_zone
          : null,
    }),
    ...(campaignType === ScheduleAndGoalsEnums.ONE_TIME && {
      ...mapDraftDataToScheduleAndGoalsOneTime(scheduleAndGoals),
    }),
    ...(campaignType === ScheduleAndGoalsEnums.PERIODIC && {
      [campaignType]: {
        ...mapDraftDataToScheduleAndGoalsPeriodic(scheduleAndGoals),
      },
    }),
    ...((campaignType === ScheduleAndGoalsEnums.EVENT_TRIGGERED ||
      campaignType === ScheduleAndGoalsEnums.DEVICE_TRIGGERED) && {
      [campaignType]: {
        ...mapDraftDataToScheduleAndGoalsEventTriggered(scheduleAndGoals),
      },
    }),
    ...(campaignType === ScheduleAndGoalsEnums.LOCATION_TRIGGERED && {
      [campaignType]: {
        ...mapDraftDataToScheduleAndGoalsLocationTriggered(scheduleAndGoals),
      },
    }),
    ...(channelType === CommonEnums.CHANNEL_INAPP && {
      campaignPriority: scheduleAndGoals?.campaign_priority
        ? {
            ...priorityMap[scheduleAndGoals.campaign_priority],
          }
        : null,
    }),
  };
  return payload;
}

export const periodicSendCampaignType = [
  ScheduleAndGoalsEnums.DAILY,
  ScheduleAndGoalsEnums.MONTHLY,
  ScheduleAndGoalsEnums.WEEKLY,
  ScheduleAndGoalsEnums.AT_SPECIFIC_DATE_AND_TIME,
];

export const mapFormValuesToImmediatelySchedule = (values, selectAudience) => {
  return {
    campaign_time_zone: `${getCurrentGMTTimeZone(values.campaignTimeZone)} ${
      values.campaignTimeZone
    }`,
    campaign_type: selectAudience?.campaignType,
    schedule_type: ScheduleAndGoalsEnums.AS_SOON_AS_POSSIBLE,
  };
};

export const mapFormValuesToPeriodicAndSpecificDateSchedule = (
  values,
  campaignType,
  channelTypes
) => {
  const scheduleTime =
    channelTypes === CommonEnums.INAPP &&
    [
      ScheduleAndGoalsEnums.DAILY,
      ScheduleAndGoalsEnums.WEEKLY,
      ScheduleAndGoalsEnums.MONTHLY,
    ].includes(values?.[campaignType]?.sendCampaignType)
      ? values[campaignType].windowStartTime
      : values[campaignType][getSendType(values, campaignType)].sendTime;

  const scheduleDate =
    values[campaignType][getSendType(values, campaignType)].startDate;
  const endsOn =
    values[campaignType][getSendType(values, campaignType)].ends ===
    ScheduleAndGoalsEnums.ON
      ? values[campaignType][getSendType(values, campaignType)].on.endDate
      : values[campaignType][getSendType(values, campaignType)].ends ===
        ScheduleAndGoalsEnums.NEVER
      ? values[campaignType][getSendType(values, campaignType)].ends
      : '';
  const endTime =
    channelTypes === CommonEnums.INAPP &&
    campaignType !== ScheduleAndGoalsEnums.PERIODIC
      ? values[campaignType][getSendType(values, campaignType)]?.on?.endTime
      : channelTypes === CommonEnums.INAPP &&
        campaignType === ScheduleAndGoalsEnums.PERIODIC
      ? values[campaignType].windowEndTime
      : null;
  const isAtSpecificTime = Boolean(
    values[campaignType].sendCampaignType ===
      ScheduleAndGoalsEnums.AT_SPECIFIC_DATE_AND_TIME
  );
  return {
    campaign_type: 0,
    schedule_type:
      values[campaignType].sendCampaignType ===
      ScheduleAndGoalsEnums.AT_SPECIFIC_DATE_AND_TIME
        ? 'at_specific_time'
        : values.campaignType,
    send_campaign_time: values[campaignType].sendCampaignTime,
    scheduling_frequency: isAtSpecificTime
      ? ''
      : values[campaignType].sendCampaignType,
    start_date:
      values[campaignType].sendCampaignTime ===
        ScheduleAndGoalsEnums.AT_FIXED_TIME ||
      values[campaignType].sendCampaignTime ===
        ScheduleAndGoalsEnums.BEST_TIME_FOR_USER
        ? moment_timeZone
            .tz(`${scheduleDate} ${scheduleTime}`, values?.campaignTimeZone)
            .unix() -
          moment_timeZone
            .tz(`${scheduleDate} ${scheduleTime}`, values?.campaignTimeZone)
            .utcOffset() *
            60
        : moment_timeZone
            .tz(`${scheduleDate} ${scheduleTime}`, CommonEnums.GMT)
            .unix(),

    end_date: endsOn
      ? values[campaignType].sendCampaignTime ===
          ScheduleAndGoalsEnums.AT_FIXED_TIME ||
        values[campaignType].sendCampaignTime ===
          ScheduleAndGoalsEnums.BEST_TIME_FOR_USER
        ? moment_timeZone
            .tz(
              `${endsOn} ${
                channelTypes === CommonEnums.INAPP && endTime
                  ? endTime
                  : channelTypes === CommonEnums.PUSH &&
                    values[campaignType].sendCampaignTime ===
                      ScheduleAndGoalsEnums.BEST_TIME_FOR_USER
                  ? values[campaignType][getSendType(values, campaignType)]
                      .sendMessagesBasedOnBestTime
                  : scheduleTime
              }`,
              values?.campaignTimeZone
            )
            .unix() -
          moment_timeZone
            .tz(
              `${endsOn} ${
                channelTypes === CommonEnums.INAPP && endTime
                  ? endTime
                  : channelTypes === CommonEnums.PUSH &&
                    values[campaignType].sendCampaignTime ===
                      ScheduleAndGoalsEnums.BEST_TIME_FOR_USER
                  ? values[campaignType][getSendType(values, campaignType)]
                      .sendMessagesBasedOnBestTime
                  : scheduleTime
              }`,
              values?.campaignTimeZone
            )
            .utcOffset() *
            60
        : moment_timeZone
            .tz(`${endsOn} ${scheduleTime}`, CommonEnums.GMT)
            .unix()
      : '',
    repeat_every: values[campaignType].repeatEvery,
    send_if_user_timezone_expired:
      values[campaignType][getSendType(values, campaignType)]
        .sendUserTimeZonePassed,
    occurrences: isAtSpecificTime
      ? null
      : endsOn
      ? null
      : Number(
          values[campaignType][getSendType(values, campaignType)].after
            .occurrences
        ),
    ...(values[campaignType].sendCampaignTime ===
      ScheduleAndGoalsEnums.BEST_TIME_FOR_USER && {
      send_message_on_best_time_till:
        moment_timeZone
          .tz(
            `${scheduleDate} ${
              values[campaignType][getSendType(values, campaignType)]
                .sendMessagesBasedOnBestTime
            }`,
            values?.campaignTimeZone
          )
          .unix() -
        moment_timeZone
          .tz(
            `${scheduleDate} ${
              values[campaignType][getSendType(values, campaignType)]
                .sendMessagesBasedOnBestTime
            }`,
            values?.campaignTimeZone
          )
          .utcOffset() *
          60,
      best_time_not_available:
        values[campaignType][getSendType(values, campaignType)]
          .userBestTimeNotAvailable,
      best_time_outside_window:
        values[campaignType][getSendType(values, campaignType)]
          .userBestTimeOutsideTimeWindow,
    }),
    repeat_on_day_of_week: isAtSpecificTime
      ? ''
      : values[campaignType].daysOfWeek,
    repeat_on_day_of_month: isAtSpecificTime
      ? ''
      : values[campaignType].daysOfMonth,
    ...(channelTypes == CommonEnums.INAPP && {
      start_time: values[campaignType].windowStartTime,
      end_time: values[campaignType].windowEndTime,
    }),
  };
};

export const mapFormValuesToOtherEventTriggerSchedule = (
  values,
  campaignType
) => {
  return {
    start_date:
      moment_timeZone
        .tz(
          `${values[campaignType].startDate} ${values[campaignType].sendTime}`,
          values?.campaignTimeZone
        )
        .unix() -
      moment_timeZone
        .tz(
          `${values[campaignType].startDate} ${values[campaignType].sendTime}`,
          values?.campaignTimeZone
        )
        .utcOffset() *
        60,
    end_date:
      moment_timeZone
        .tz(
          `${values[campaignType].endDate} ${values[campaignType].endTime}`,
          values?.campaignTimeZone
        )
        .unix() -
      moment_timeZone
        .tz(
          `${values[campaignType].endDate} ${values[campaignType].endTime}`,
          values?.campaignTimeZone
        )
        .utcOffset() *
        60,
  };
};

export const mapDraftDataToScheduleAndGoalsLocationTriggered = (values) => {
  return {
    timebound: values?.time_bound ?? ScheduleAndGoalsEnums.ALWAYS,
    sendCampaignType: ScheduleAndGoalsEnums.IMMEDIATELY,
    time_bound_list: values?.time_bound_list?.length
      ? settimeBoundList(values?.time_bound_list)
      : mapTimeboundArrayvalues(values),
  };
};

export const mapTimeboundArrayvalues = (values) => {
  return [
    {
      campaign_time_zone: values?.time_bound_list?.length
        ? values?.time_bound_list?.[0]?.campaign_time_zone
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
      startDate: moment().format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      endDate: moment().format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
      sendTime: moment().format(TIME_FORMAT_WITHOUT_DATE),
      endTime: moment().format(TIME_FORMAT_WITHOUT_DATE),
    },
  ];
};

export const settimeBoundList = (timeBound) => {
  let timeBoundArray = [];
  if (timeBound?.length)
    for (let i = 0; i < timeBound.length; i++) {
      timeBoundArray.push({
        campaign_time_zone: timeBound[i]?.campaign_time_zone
          ? timeBound[i]?.campaign_time_zone?.split(' ')?.[1]
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
        startDate: timeBound[i]?.start_date
          ? moment_timeZone
              .unix(timeBound[i]?.start_date)
              .tz(timeBound[i]?.campaign_time_zone)
              ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(timeBound[i]?.campaign_time_zone)
              ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
        endDate: timeBound[i]?.end_date
          ? moment_timeZone
              .unix(timeBound[i]?.end_date)
              .tz(timeBound[i]?.campaign_time_zone)
              ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY)
          : moment_timeZone
              .tz(timeBound[i]?.campaign_time_zone)
              ?.format(DATE_FORMAT_WITHOUT_TIME_DD_MMM_YYYY),
        sendTime: timeBound[i]?.start_date
          ? moment_timeZone
              .unix(timeBound[i]?.start_date)
              .tz(timeBound[i]?.campaign_time_zone)
              .format(TIME_FORMAT_WITHOUT_DATE)
          : moment_timeZone
              .tz(timeBound[i]?.campaign_time_zone)
              .format(TIME_FORMAT_WITHOUT_DATE),
        endTime: timeBound[i]?.end_date
          ? moment_timeZone
              .unix(timeBound[i]?.end_date)
              .tz(timeBound[i]?.campaign_time_zone)
              .format(TIME_FORMAT_WITHOUT_DATE)
          : moment_timeZone
              .tz(timeBound[i]?.campaign_time_zone)
              .format(TIME_FORMAT_WITHOUT_DATE),
      });
    }
  return timeBoundArray;
};

export const timeBoundList = (timeBound) => {
  let timeBoundArray = [];
  if (timeBound?.length)
    for (let i = 0; i < timeBound.length; i++) {
      timeBoundArray.push({
        campaign_time_zone: `${getCurrentGMTTimeZone(
          timeBound[i].campaign_time_zone
        )} ${timeBound[i].campaign_time_zone}`,
        start_date:
          moment_timeZone
            .tz(
              `${timeBound[i]?.startDate} ${timeBound[i]?.sendTime}`,
              timeBound[i].campaign_time_zone
            )
            .unix() -
          moment_timeZone
            .tz(
              `${timeBound[i]?.startDate} ${timeBound[i]?.sendTime}`,
              timeBound[i].campaign_time_zone
            )
            .utcOffset() *
            60,
        end_date:
          moment_timeZone
            .tz(
              `${timeBound[i]?.endDate} ${timeBound[i]?.endTime}`,
              timeBound[i].campaign_time_zone
            )
            .unix() -
          moment_timeZone
            .tz(
              `${timeBound[i]?.endDate} ${timeBound[i]?.endTime}`,
              timeBound[i].campaign_time_zone
            )
            .utcOffset() *
            60,
      });
    }
  return timeBoundArray;
};

export const mapFormValuesToOtherCampaignTypeSchedule = (
  values,
  selectAudience,
  campaignType
) => {
  return {
    campaign_type: selectAudience.campaignType,
    campaign_time_zone: `${getCurrentGMTTimeZone(values?.campaignTimeZone)} ${
      values?.campaignTimeZone
    }`,
    schedule_type:
      selectAudience.campaignType === ScheduleAndGoalsEnums.EVENT_TRIGGERED ||
      selectAudience.campaignType === ScheduleAndGoalsEnums.DEVICE_TRIGGERED
        ? selectAudience.campaignType
        : values[campaignType].sendCampaignType,
    ...((selectAudience.campaignType !==
      ScheduleAndGoalsEnums.EVENT_TRIGGERED ||
      selectAudience.campaignType !==
        ScheduleAndGoalsEnums.DEVICE_TRIGGERED) && {
      schedule_date: moment(values[campaignType].sendDate).unix(),
    }),

    ...((selectAudience.campaignType ===
      ScheduleAndGoalsEnums.EVENT_TRIGGERED ||
      selectAudience.campaignType ===
        ScheduleAndGoalsEnums.DEVICE_TRIGGERED) && {
      ...mapFormValuesToOtherEventTriggerSchedule(values, campaignType),
      time_multiplier: selectAudience.triggerCriteria.time_multiplier,
      time_value: selectAudience.triggerCriteria.time_value,
      ...(selectAudience.triggerCriteria.trigger_message_type ===
        TargetAudienceEnums.WITH_DELAY && {
        trigger_attr: selectAudience.triggerCriteria.trigger_attr,
        trigger_relation: selectAudience.triggerCriteria.trigger_relation,
      }),
    }),
    ...(selectAudience.campaignType ===
      ScheduleAndGoalsEnums.LOCATION_TRIGGERED && {
      campaign_type: ScheduleAndGoalsEnums.LOCATION_TRIGGERED,
      schedule_type: ScheduleAndGoalsEnums.AS_SOON_AS_POSSIBLE,
      time_bound: values?.[campaignType]?.timebound,
      time_bound_list:
        values?.[campaignType]?.timebound ===
        ScheduleAndGoalsEnums.LIMITED_PERIOD
          ? timeBoundList(values?.[campaignType]?.time_bound_list)
          : [],
      schedule_date: undefined,
      campaign_time_zone:
        values?.[campaignType]?.timebound !==
        ScheduleAndGoalsEnums.LIMITED_PERIOD
          ? `${getCurrentGMTTimeZone(values?.campaignTimeZone)} ${
              values?.campaignTimeZone
            }`
          : null,
      schedule_time:
        values?.[campaignType]?.timebound !==
        ScheduleAndGoalsEnums.LIMITED_PERIOD
          ? moment().unix()
          : null,
      start_date:
        values?.[campaignType]?.timebound !==
        ScheduleAndGoalsEnums.LIMITED_PERIOD
          ? moment().unix()
          : null,
    }),
  };
};

export const mapGeoFenceValues = (geoFenceData, valueMapping) => {
  let finalGeoFenceResult = [];
  if (geoFenceData?.length) {
    for (let value of geoFenceData) {
      let v = valueMapping
        ? getLocalGeoFenceValues(value)
        : getGeoFenceValues(value);
      finalGeoFenceResult.push(v);
    }
  }
  return finalGeoFenceResult;
};

export const getLocationArray = (values) => {
  let finalLocation = [];
  for (let location of values) {
    finalLocation.push(getLocalGeoFenceValues(location));
  }
  return finalLocation;
};

export const getLocalGeoFenceValues = (values) => {
  return {
    id: values?.id ?? null,
    _destroy: values?._destroy ?? false,
    geofenceName: values?.geofence_name ?? '',
    latitude: values?.latitude,
    longitude: values?.longitude,
    radius: values?.radius,
    notificationResponsiveness: values?.notification_responsiveness ?? 0,
    triggeredAt: values?.triggered_at,
    unit: values?.unit,
    day_unit: values?.day_unit,
    dwellTime: values?.dwell_time ?? 0,
    place: values?.place ?? '',
  };
};

export const getGeoFenceValues = (values) => {
  return {
    id: values?.id ?? null,
    _destroy: values?._destroy ?? false,
    geofence_name: values?.geofenceName ?? '',
    latitude: +values?.latitude,
    longitude: +values?.longitude,
    radius: +values?.radius,
    notification_responsiveness: +values?.notificationResponsiveness ?? 0,
    triggered_at: values?.triggeredAt,
    unit: values?.unit,
    day_unit: values?.day_unit,
    dwell_time: +values?.dwellTime ?? 0,
    place: values?.place,
  };
};
