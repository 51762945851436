import {
  GET_TOTAL_AGENT_COUNT,
  GET_TOTAL_AGENT_COUNT_SUCCESS,
  GET_ACTIVE_CHAT_COUNT,
  GET_ACTIVE_CHAT_COUNT_SUCCESS,
  GET_TOTAL_ACTIVE_CHAT_COUNT_SUCCESS,
  GET_INSIGHTS_DETAILS,
  GET_INSIGHTS_DETAILS_SUCCESS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS_SUCCESS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS_ERROR,
  GET_ACTIVE_AGENT_SESSION,
  GET_ACTIVE_AGENT_SESSION_SUCCESS,
  GET_AUDIENCE_DEMOGRAPHIC,
  GET_AUDIENCE_DEMOGRAPHIC_SUCCESS,
  GET_AUDIENCE_COUNTRY_LIST,
  GET_AUDIENCE_COUNTRY_LIST_SUCCESS,
} from 'redux/constants';

const INIT_STATE = {
  totalLiveAgent: 0,
  totalActiveAgent: 0,
  loadedTotalLiveAgent: false,
  activeAgentSession: 0,
  loadedActiveAgentSession: false,
  activeChatCount: 0,
  loadedactiveChatCount: false,
  totalactiveChatCount: 0,
  loadedTotalActiveChatCount: false,
  insightsDetails: {
    avg_first_response_time: { data: [], loader: false },
    avg_resolution_time: { data: [], loader: false },
    active_conversation_count: { data: [], loader: false },
    unique_chat_count: { data: { data: [] }, loader: false },
    read_messages_count: { data: [], loader: false },
    outgoing_messages_count: { data: [], loader: false },
    longest_handling_time: { data: [], loader: false },
    track_login_hours_by_agent: { data: { data: [] }, loader: false },
    active_users_count: { data: { data: [] }, loader: false },
    resolutions_count: { data: [], loader: false },
    active_channels_count: { data: { data: [] }, loader: false },
    live_agents_report: { data: { data: [] }, loader: false },
  },
  audienceDemographic: {},
  loaderAudienceDemographic: false,
  audienceCountryList: [],
  loadedAudienceCountryList: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TOTAL_AGENT_COUNT:
      return { ...state, loadedTotalLiveAgent: true };

    case GET_TOTAL_AGENT_COUNT_SUCCESS:
      return {
        ...state,
        totalLiveAgent: action.payload.live_agent_count,
        totalActiveAgent: action.payload.active_agent_count,
      };

    case GET_ACTIVE_AGENT_SESSION:
      return { ...state, loadedActiveAgentSession: true };

    case GET_ACTIVE_AGENT_SESSION_SUCCESS:
      return {
        ...state,
        activeAgentSession: action.payload.total_sessions_count,
      };

    case GET_ACTIVE_CHAT_COUNT:
      return { ...state, loadedactiveChatCount: true };

    case GET_ACTIVE_CHAT_COUNT_SUCCESS:
      return {
        ...state,
        activeChatCount: action.payload.response?.data?.[0]?.total_count ?? 0,
      };

    case GET_TOTAL_ACTIVE_CHAT_COUNT_SUCCESS:
      return {
        ...state,
        totalactiveChatCount:
          action.payload.response?.data?.[0]?.total_count ?? 0,
      };

    case GET_INSIGHTS_DETAILS:
      return {
        ...state,
        insightsDetails: {
          ...state.insightsDetails,
          [action.payload.metric]: {
            ...state.insightsDetails[action.payload.metric],
            loader: true,
          },
        },
      };

    case GET_INSIGHTS_DETAILS_SUCCESS:
      return {
        ...state,
        insightsDetails: {
          ...state.insightsDetails,
          [action.payload.payload.metric]: {
            data: action.payload.response,
            loader: false,
          },
        },
      };

    case DOWNLOAD_DATA_INSIGHTS_REPORTS:
      return {
        ...state,
        exportSuccess: false,
        exportError: false,
        errorMsg: {},
      };

    case DOWNLOAD_DATA_INSIGHTS_REPORTS_SUCCESS:
      return {
        ...state,
        exportSuccess: true,
        exportError: false,
        errorMsg: {},
      };

    case DOWNLOAD_DATA_INSIGHTS_REPORTS_ERROR:
      return {
        ...state,
        exportSuccess: '',
        exportError: true,
        errorMsg: action.payload,
      };

    case GET_AUDIENCE_DEMOGRAPHIC:
      return {
        ...state,
        loaderAudienceDemographic: true,
      };

    case GET_AUDIENCE_DEMOGRAPHIC_SUCCESS:
      return {
        ...state,
        loaderAudienceDemographic: false,
        audienceDemographic: action.payload.response,
      };

    case GET_AUDIENCE_COUNTRY_LIST:
      return {
        ...state,
        loadedAudienceCountryList: true,
      };

    case GET_AUDIENCE_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        audienceCountryList: action.payload,
        loadedAudienceCountryList: false,
      };

    default:
      return { ...state };
  }
};
