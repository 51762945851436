import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import axios from 'axios';
import {
  apiUrlNew,
  getHeaders,
  commonRequestException,
  apiUrlNewV3,
} from 'helpers/ApiHelper';

import {
  ACCOUNT_GET,
  ACCOUNTS_GET,
  ACCOUNTS_DELETE,
  ACCOUNTS_ADD,
  CUSTOM_DOMAIN_ADD,
  CUSTOM_DOMAIN_REMOVE,
  GET_APPID,
} from 'redux/constants';

import {
  getAccountSuccess,
  getAccountsSuccess,
  deleteAccountSuccess,
  deleteAccountError,
  addAccountSuccess,
  addAccountError,
  addCustomDomainSuccess,
  addCustomDomainError,
  removeCustomDomainSuccess,
  removeCustomDomainError,
  getAppIdSuccess,
  getAppIdFailure,
} from './actions';

const getAccountAsync = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNew()}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getAccount({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getAccountAsync, payload);
    yield put(getAccountSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const getAccountsAsync = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNew()}accounts`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getAccounts({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(getAccountsAsync, payload);
    yield put(getAccountsSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const deleteAccountRequest = async (postData) => {
  const method = 'delete';
  return axios[method](`${apiUrlNew()}accounts/${postData.id}`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* deleteAccount({ payload }) {
  try {
    const response = yield call(deleteAccountRequest, payload);
    yield put(deleteAccountSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(deleteAccountError(errorMsg));
  }
}

const addAccountRequest = async (postData) => {
  const method = postData.id ? `patch` : 'post';
  // const itemIdStr = postData.id ? `/${postData.id}` : '';
  const itemIdStr = '/';
  return axios[method](`${apiUrlNew()}${itemIdStr}`, postData, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* addAccount({ payload }) {
  try {
    const response = yield call(addAccountRequest, payload);
    yield put(addAccountSuccess({ response, payload }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(addAccountError(errorMsg));
  }
}

const addCustomDomainRequest = async (postData) => {
  const method = postData.id ? `patch` : 'post';
  return axios[method](`${apiUrlNewV3()}`, postData, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* addCustomDomain({ payload }) {
  try {
    const response = yield call(addCustomDomainRequest, payload);
    yield put(addCustomDomainSuccess({ response }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(addCustomDomainError(errorMsg));
  }
}

function* removeCustomDomain({ payload }) {
  try {
    const response = yield call(addCustomDomainRequest, payload);
    yield put(removeCustomDomainSuccess({ response }));
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(removeCustomDomainError(errorMsg));
  }
}

const getAppIdAsync = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}setting`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getAppIdData() {
  try {
    const response = yield call(getAppIdAsync);
    yield put(getAppIdSuccess(response));
  } catch (error) {
    yield put(getAppIdFailure());
  }
}

export function* watchGetAccounts() {
  yield takeEvery(ACCOUNT_GET, getAccount);
  yield takeEvery(ACCOUNTS_GET, getAccounts);
  yield takeEvery(ACCOUNTS_DELETE, deleteAccount);
  yield takeEvery(ACCOUNTS_ADD, addAccount);
  yield takeEvery(CUSTOM_DOMAIN_ADD, addCustomDomain);
  yield takeEvery(CUSTOM_DOMAIN_REMOVE, removeCustomDomain);
  yield takeEvery(GET_APPID, getAppIdData);
}

export default function* rootSaga() {
  yield all([fork(watchGetAccounts)]);
}
