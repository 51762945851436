const CommonEnums = Object.freeze({
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  PUSH: 'push',
  INAPP: 'in_app',
  CHANNEL_INAPP: 'InApp',
  MOBILE: 'mobile',
  ANDROID: 'android',
  IOS: 'ios',
  LOCATION_TRIGGERED: 'location_trigger',
  ALL_PLATFORM: 'all_platform',
  TATA_SMSC: 'tata_smsc',
  TATA_WHATSAPP: 'tata_whatsapp',
  TATA_SMS: 'tatasmsc',
  BUTTONS: 'buttons',
  BUTTON: 'button',
  WHATSAPP_TATA_COMMUNICATION: 'whatsapp_tata_communications',
  NONE: 'none',
  VALID_DATE: 'Please enter a valid date',
  NO_AGENT: 'NONE',
  INPUT_CHANGE: 'input-change',
  MENU_CLOSE: 'menu-close',
  TEST_CAMPAIGN: {
    MOBILE_NUMBER: 'mobileNumber',
    UNIQUE_ID: 'unique_id',
    EMAIL_ID: 'emailId',
    CUSTOM_SEGMENT: 'custom_segment',
    DEVICE_TOKEN: 'deviceToken',
    SELECTED_OPTIONS: {
      MOBILE_NUMBER: 'phone_number',
      UNIQUE_ID: 'unique_id',
      EMAIL_ID: 'email',
      CUSTOM_SEGMENT: 'custom_segment',
      DEVICE_TOKEN: 'device_token',
    },
  },
  GENDER: {
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
  },
  TYPE_STRING: 'string',
  SUBMIT: 'submit',
  EDIT: 'edit',
  TEST: 'test',
  NEW: 'new',
  SMS_LABEL: 'SMS',
  WHATSAPP_LABEL: 'Whatsapp',
  EMAIL_LABEL: 'Email',
  PUSH_LABEL: 'Push',
  INAPP_LABEL: 'In App',
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  ERROR: 'Error',
  AS_SOON_AS_POSSIBLE: 'as_soon_as_possible',
  PERIODIC: 'periodic',
  AT_SPECIFIC_TIME: 'at_specific_time',
  REGISTERED_AUDIENCE: 'registered_audience',
  ANONYMOUS_AUDIENCE: 'anonymous_audience',
  EVENT: 'event',
  ANALYTICS: {
    DELIVERY_RATE: 'delivery_rate',
    CLICK_THROUGH_RATE: 'click_through_rate',
  },
  PROCESSING: 'processing',
  PENDING: 'pending',
  BODY: 'BODY',

  CHANNEL_NAME_API_MOBILE_PUSH: 'mobile_push',
  PLATFORM_ANDROID: 'android',
  PLATFORM_IOS: 'ios',
  PRIVATE_KEY_FILE: 'private_key_file',
  FCM_SERVER_KEY: 'fcm_server_key',

  APNS_AUTHENTICATION_KEY: 'apns_authentication_key',
  APNS_PROVIDER_CERTIFICATE: 'apns_provider_certificate',
  MENU_DEFAULT: 'menu-default',
  UTC: 'UTC',
  CSAT: {
    LABEL_1: 'Total_responses',
    LABEL_2: 'Satisfaction_score',
    LABEL_3: 'Response_rate',
    RED: 'red',
    ORANGE: 'orange',
    GOLD: 'gold',
    YELLOW: 'yellow',
    GREEN: 'green',
  },
  EN_US: 'en-US',
  SHORT: 'short',
  NUMERIC: 'numeric',
  TWO_DIGIT: '2-digit',
  FORM_GROUP_CUSTOM: {
    IMAGE: 'image',
    TEXTAREA: 'textarea',
    SELECT: 'select',
    RADIO_MULTI: 'radioMulti',
    CHECKBOX_MULTI: 'checkboxMulti',
    FILE: 'file',
    DATE: 'date',
    TAG: 'tag',
    NUMBER: 'number',
    COLOR_PICKER_INPUT: 'colorPickerInput',
    SLIDER_WITH_INPUT: 'sliderWithInput',
  },
  AGENT: 'agent',
  GMT: 'GMT',
  ORDER: {
    ASCENDING: 'ascending',
    DECENDING: 'descending',
  },
  INAPP_ALIGNMENT: {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    LEFT: 'left',
    CENTER: 'center',
  },
  INAPP_CONTENT_TYPE: {
    NATIVE: 'NATIVE',
    HTML: 'HTML',
    HTML_TEXT: 'HtmlText',
    APP_OPEN: 'APP_OPEN',
    TEST_INAPP: 'test_in_app',
  },
  INAPP_BTN_ACTION: {
    SHARE: 'share',
    VISIT_URL: 'visit_url',
    TRACK_EVENT: 'track_event',
  },
  DATE_TIME_FORMAT: 'DD MMMM YYYY hh:mm:A',
  PHONE_REG_EXP:
    /^(\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1))[- ]?\d{1,14}$/,
  MAP_ATTRIBUTES: {
    STROKE_COLOR: '#FF0000',
    STROKE_OPACITY: 0.8,
    STROKE_WEIGHT: 1,
    FILL_COLOR: '#edf7f0',
    FILL_OPACITY: 0.4,
    RADIUS_MULTIPLIER: 1,
    LIBRARY_LIST: ['places'],
  },
  RECENT_EVENT: {
    SUCCESS_EVENTS: 'Successful Events',
    FAIL_EVENT: 'Failure Events',
  },
  TIME_UNIT: 'seconds',
  WEEK_DAYS: {
    Sunday: 1,
    Monday: 2,
    Tuesday: 3,
    Wednesday: 4,
    Thursday: 5,
    Friday: 6,
    Saturday: 7,
  },
});

export default CommonEnums;
