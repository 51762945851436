const ContentConfigurationEnums = Object.freeze({
  LIMIT_PER_MESSAGE: 160,
  INTEGER: 'integer',
  NUMBER: 'number',
  PREVIEW_MESSAGE: 'Message preview will be displayed here',
  TWO_COLUMNS: 'twoColumns',
  THREE_COLUMNS: 'threeColumns',
  FOUR_COLUMNS: 'fourColumns',
  LOCAL_FILE: 'localFile',
  UPLOAD_TEMPLATE: 'uploadTemplate',
  TOTAL_PAGE_COUNT: '10',
  EDIT_TEMPLATE: 'editTemplate',
  BODY: 'body',
  NAME: 'name',
  HTML: 'text/html',
  DOWNLOAD_NAME: 'template.html',
  DESIGN: 'design',
  HTML_STYLE: 'none',
  WHATSAPP_HEADER: 'HEADER',
  WHATSAPP_BODY: 'BODY',
  WHATSAPP_FOOTER: 'FOOTER',
  WHATSAPP_BUTTONS: 'BUTTONS',
  TCLSMS: 'TCLSMS',
  CONNECTOR: 'Please provide the connector name',
  API_KEY: 'Please provide the API key',
  API_URL: 'Please provide the API URL',
  API_KEY_HIDDEN: '*********',
  WITH_MEDIA: 'With Media',
  WITHOUT_MEDIA: 'Without Media',
  MEDIA: '<<media>>',
  HEADER: 'header',
  WHATSAPP_FLOW_BUTTONS: 'FLOW',
  TEMPLATE_UPDATE: 'updateTemplate',
  SAVE_AS_NEW_TEMPLATE: 'saveNewTemplate',
  REGEX_TINY:
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g, //eslint-disable-line
  SLIDER_DEFAULTS: {
    MIN_VAL: 0,
    MAX_VAL: 100,
    DEFAULT: 10,
  },
  INAPP_UPLOAD_IMAGE: 'uploadImage',
  INAPP_IMAGE_LABEL: 'Upload Image',
  INAPP_IMAGE_URL: 'imageUrl',
  INAPP_IMAGE_URL_LABEL: 'Image URL',
  INAPP_BACKGROUND_COLOR: 'Background Color',
  INAPP_BACKGROUND_COLOR_VALUE: 'backgroundColor',
  INAPP_NONE: 'None',
  INAPP_NONE_VALUE: 'none',
  INAPP_ID: 'Please provide the ID of the template',
  PUSH: {
    PUSH_IMAGE_URL: 'imageUrl',
    PUSH_UPLOAD_IMAGE: 'uploadImage',
    ANDROID: 'android',
    IOS: 'ios',
    IPHONE: 'iphone',
    IPAD: 'ipad',
    IMAGE_UPLOAD_LIMIT_ANDROID: 1048576,
    IMAGE_UPLOAD_LIMIT_IOS: 5242880,
    MESSAGE_SUMMARY_LENGTH_ANDROID: 40,
    MESSAGE_TITLE_LENGTH_ANDROID: 65,
    MESSAGE_LENGTH_ANDROID: 240,
    MESSAGE_TITLE_LENGTH_IOS: 40,
    SUB_TITLE_LENGTH_IOS: 70,
    MESSAGE_LENGTH_IOS: 120,
  },
  INAPP_PREDEFINED_COLOR_CODE: {
    IMG_BACKGROUND_COLOR: '#ffffff',
    TEXT_BACKGROUND_COLOR: '#ffffff',
    TEXT_FONT_COLOR: '#000000',
    ACTION_BACKGROUND_COLOR: '#c84646',
    ACTION_BTN_COLOR: '#d63548',
    ACTION_FONT_COLOR: '#ffffff',
  },
  INAPP: {
    CONTENT_TYPE: {
      HTML: 'html',
      NATIVE: 'native',
    },
    TEMPLATE_STATE: {
      DRAFT: 'draft',
      SAVED: 'saved',
      PUBLISHED: 'published',
    },
    SAVED_TEMPLATES_PER_PAGE: 12,
  },
  SAVE_TEMPLATES_DEFAULT_PAGE: 1,
});

export default ContentConfigurationEnums;
