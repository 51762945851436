import {
  GET_FAILED_EVENTS,
  GET_FAILED_EVENTS_SUCCESS,
  GET_RECENT_EVENTS,
  GET_RECENT_EVENTS_SUCCESS,
} from 'redux/constants';

const INIT_STATE = {
  eventList: [],
  pagination: {},
  failedEvents: [],
  failedEventsPagination: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECENT_EVENTS:
      return {
        ...state,
      };
    case GET_RECENT_EVENTS_SUCCESS:
      return {
        ...state,
        eventList: action?.payload?.result,
        pagination: action?.payload?.pagination,
        recentEventSuccess: true,
      };
    case GET_FAILED_EVENTS:
      return {
        ...state,
      };
    case GET_FAILED_EVENTS_SUCCESS:
      return {
        ...state,
        failedEvents: action?.payload?.result,
        failedEventsPagination: action?.payload?.pagination,
        recentEventSuccess: true,
      };
    default:
      return { ...state };
  }
};
