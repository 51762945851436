const dataInsightsEnum = Object.freeze({
  MATRIC: {
    AVERAGE_FIRST_RESPONSE_TIME: 'avg_first_response_time',
    AVERAGE_HANDLING_TIME: 'avg_resolution_time',
    ACTIVE_CONVERSATION_COUNT: 'active_conversation_count',
    UNIQUE_CHAT_COUNT: 'unique_chat_count',
    READ_MESSAGE_COUNT: 'read_messages_count',
    OUTGOING_MESSAGES_COUNT: 'outgoing_messages_count',
    LONGEST_HANDLING_TIME: 'longest_handling_time',
    TRACK_LOGIN_HOURS_BY_AGENT: 'track_login_hours_by_agent',
    DELIVERY_STATUS: 'delivery_status',
    ACTIVE_CHANNELS_COUNT: 'active_channels_count',
    ACTIVE_USERS_COUNT: 'active_users_count',
    RESOLUTIONS_COUNT: 'resolutions_count',
    LIVE_AGENTS_REPORT: 'live_agents_report',
  },
  TYPE: {
    AGENT: 'agent',
    ACCOUNT: 'account',
  },
  CHART_TYPE: {
    BAR: 'bar',
    DOUGHNUT: 'doughnut',
    STACKED: 'stacked',
  },
  ROUTE: {
    ACTIVE_CONVERSATIONS: 'active_conversations',
    LONGEST_HANDLING_TIME: 'longest_handling_time',
    TRACK_LOGIN_HOURS_BY_AGENT: 'track_login_hours_by_agent',
    UNIQUE_CHAT_COUNT: 'unique_chats_by_agent',
    ACTIVE_CHANNELS: 'active_channels',
    LIVE_AGENT_METRICS: 'live_agent_metrics',
    ACTIVE_USERS: 'active_users',
  },
  TIMEZONE_OFFSET: 5.5,
  GROUP_BY_VALUE: {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
  },
  API_VERSION: {
    V2: [
      'avg_first_response_time',
      'avg_resolution_time',
      'outgoing_messages_count',
      'resolutions_count',
    ],
    V3: ['active_conversation_count', 'read_messages_count'],
  },
  DATE_VALUE: {
    LAST_7_DAYS: 6,
    LAST_30_DAYS: 29,
    LAST_3_MONTHS: 89,
    LAST_6_MONTHS: 179,
    LAST_YEAR: 364,
  },
  STACK_CHART_CATEGORY: {
    DELIVERY_STATUS: 'DELIVERY_STATUS',
    ACTIVE_CHANNEL: 'ACTIVE_CHANNEL',
    LABEL: {
      DELIVERY_STATUS: {
        LABEL_ONE: 'Delivered',
        LABEL_TWO: 'Pending',
        LABEL_THREE: 'Failed',
      },
      ACTIVE_CHANNEL: {
        LABEL_ONE: 'Sms',
        LABEL_TWO: 'WhatsApp',
        LABEL_THREE: 'Email',
      },
    },
  },
  AUDIENCE_DEMOGRAPHIC: {
    OPEN: 'Open',
    PENDING: 'Pending',
    RESOLVED: 'Resolved',
    SNOOZED: 'Snoozed',
  },
  CHART_UNIT: {
    HOUR: ' ( hour )',
    SEC: ' ( sec )',
  },
});

export default dataInsightsEnum;
