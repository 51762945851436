const TargetAudienceEnums = Object.freeze({
  SMS: 'sms',
  WITH_DELAY: 'with_delay',
  IMMEDIATELY: 'immediately',
  TIME_VALUE: 1,
  TIME_MULTIPLIER: 60,
  AFTER: 'after',
  IF_ACTION: 'If Action',
  TRIGGER_CRITERIA: 'triggerCriteria',
  INCLUDED_FILTERS: 'included_filters',
  AT_LEAST: 'at_least',
  INAPP_APP_OPEN: 'on_app_open',
  APP_OPEN: 'APP_OPEN',
  INAPP_EVENT: 'on_event_trigger',
});

export default TargetAudienceEnums;
