import axios from 'axios';
import {
  apiUrlNewV2,
  apiUrlNewV3,
  commonRequestException,
  getHeaders,
} from 'helpers/ApiHelper';
import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  getTotalAgentCountSuccess,
  getActiveChatCountSuccess,
  getInsightsDetailsSuccess,
  downloadDataAndInSightsReportsSuccess,
  downloadDataAndInSightsReportsError,
  getTotalActiveChatCountSuccess,
  getActiveAgentSessionSuccess,
  getAudienceDemographicSuccess,
  getAudienceCountryListSuccess,
} from './actions';
import {
  GET_TOTAL_AGENT_COUNT,
  GET_ACTIVE_AGENT_SESSION,
  GET_ACTIVE_CHAT_COUNT,
  GET_INSIGHTS_DETAILS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS,
  GET_AUDIENCE_DEMOGRAPHIC,
  GET_AUDIENCE_COUNTRY_LIST,
} from 'redux/constants';
import dataInsightsEnum from 'enums/data-insights/dataInsightsEnum';

const getTotalAgentCountRequest = async () => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}user_session_information/agents_count`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* getTotalAgentCount() {
  try {
    const response = yield call(getTotalAgentCountRequest);
    yield put(getTotalAgentCountSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const getActiveAgentSessionRequest = async () => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}user_session_information/active_agent_session`,
    {
      headers: getHeaders(),
    }
  ).then((res) => {
    return res.data;
  });
};

function* getActiveAgentSession() {
  try {
    const response = yield call(getActiveAgentSessionRequest);
    yield put(getActiveAgentSessionSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

const getActiveChatCountRequest = async (param) => {
  const method = 'get';
  return axios[method](
    `${apiUrlNewV3()}user_session_information/active_chat_count_by_agent`,
    {
      headers: getHeaders(),
      params: param,
    }
  ).then((res) => {
    return res.data;
  });
};

function* getActiveChatCount({ payload }) {
  try {
    const response = yield call(getActiveChatCountRequest, payload);
    if (payload.type === dataInsightsEnum.TYPE.AGENT) {
      yield put(getActiveChatCountSuccess({ response, payload }));
    } else if (payload.type === dataInsightsEnum.TYPE.ACCOUNT) {
      yield put(getTotalActiveChatCountSuccess({ response, payload }));
    }
  } catch (error) {
    commonRequestException(error);
  }
}

const getInsightsDetailsRequest = async (param) => {
  const method = 'get';
  const apiURL = dataInsightsEnum.API_VERSION.V2.includes(param.metric)
    ? apiUrlNewV2()
    : apiUrlNewV3();
  if (param?.route) {
    return axios[method](`${apiURL}reports/${param?.route}`, {
      headers: getHeaders(),
      params: param,
    }).then((res) => {
      return res.data;
    });
  } else {
    return axios[method](`${apiURL}reports`, {
      headers: getHeaders(),
      params: param,
    }).then((res) => {
      return res.data;
    });
  }
};

function* getInsightsDetails({ payload }) {
  try {
    const response = yield call(getInsightsDetailsRequest, payload);
    yield put(getInsightsDetailsSuccess({ response, payload }));
  } catch (error) {
    commonRequestException(error);
  }
}

const downloadDataAndInSightsReportsRequest = async (param) => {
  const method = `get`;
  return axios[method](`${apiUrlNewV3()}reports.${param.exportType}`, {
    params: param.payload,
    headers: getHeaders(),
    responseType: 'blob',
  }).then((res) => {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = `${param.payload.metric}_reports_group_by_${param.payload.group_by}.${param.exportType}`;
    a.click();
    return res.data;
  });
};

function* downloadDataAndInSightsReports({ payload }) {
  try {
    yield call(downloadDataAndInSightsReportsRequest, payload);
    yield put(downloadDataAndInSightsReportsSuccess());
  } catch (error) {
    const errorMsg = commonRequestException(error);
    yield put(downloadDataAndInSightsReportsError(errorMsg));
  }
}

const getAudienceDemographicRequest = async (param) => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}reports/audience_demographic`, {
    headers: getHeaders(),
    params: param,
  }).then((res) => {
    return res.data;
  });
};

function* getAudienceDemographic({ payload }) {
  try {
    const response = yield call(getAudienceDemographicRequest, payload);
    yield put(getAudienceDemographicSuccess({ response, payload }));
  } catch (error) {
    commonRequestException(error);
  }
}

const getAudienceCountryListRequest = async () => {
  const method = 'get';
  return axios[method](`${apiUrlNewV3()}get_country_list`, {
    headers: getHeaders(),
  }).then((res) => {
    return res.data;
  });
};

function* getAudienceCountryList() {
  try {
    const response = yield call(getAudienceCountryListRequest);
    yield put(getAudienceCountryListSuccess(response));
  } catch (error) {
    commonRequestException(error);
  }
}

export function* watchDataInsights() {
  yield takeLatest(GET_TOTAL_AGENT_COUNT, getTotalAgentCount);
  yield takeLatest(GET_ACTIVE_AGENT_SESSION, getActiveAgentSession);
  yield takeLatest(GET_ACTIVE_CHAT_COUNT, getActiveChatCount);
  yield takeEvery(GET_INSIGHTS_DETAILS, getInsightsDetails);
  yield takeEvery(
    DOWNLOAD_DATA_INSIGHTS_REPORTS,
    downloadDataAndInSightsReports
  );
  yield takeLatest(GET_AUDIENCE_DEMOGRAPHIC, getAudienceDemographic);
  yield takeEvery(GET_AUDIENCE_COUNTRY_LIST, getAudienceCountryList);
}

export default function* rootSaga() {
  yield all([fork(watchDataInsights)]);
}
