import {
  CREATE_CONTROL_GROUPS,
  CREATE_CONTROL_GROUPS_SUCCESS,
  CREATE_CONTROL_GROUPS_FAILURE,
  CREATE_CONTROL_GROUPS_SUCCESS_RESET,
  DELETE_CONTROL_GROUPS,
  DELETE_CONTROL_GROUPS_SUCCESS,
  DELETE_CONTROL_GROUPS_SUCCESS_RESET,
  GET_CONTROL_GROUPS_SUCCESS,
  DOWNLOAD_CONTROL_GROUPS_SUCCESS,
  DOWNLOAD_CONTROL_GROUPS_RESET,
  DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_SUCCESS,
  DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_RESET,
  ENABLE_CONTROL_GROUPS,
  ENABLE_CONTROL_GROUPS_SUCCESS,
  GET_STATUS_GLOBAL_CONTROL_GROUP_SUCCESS,
  GLOBAL_CONTROL_GROUPS_TOGGLE,
  GET_CAMPAIGNS_WITH_CONTROL_GROUP,
  GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_FAILURE,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS_RESET,
  EDIT_CONTROL_GROUPS,
  EDIT_CONTROL_GROUPS_RESET,
  RESUBMIT_REQUEST,
  RESUBMIT_REQUEST_RESET,
} from 'redux/constants';

const INIT_STATE = {
  controlGroups: {},
  controlGroupsSuccess: false,
  controlGroupsFailure: false,
  editControlGroups: false,
  deleteControlGroupsSuccess: false,
  controlGroupsError: '',
  statusControlGroup: {},
  statusControlGroupSuccess: false,
  running_without_global_control_group: 0,
  running_with_global_control_group: 0,
  campaignControlGroupChannels: {},
  campaignControlGroupChannelsSuccess: false,
  campaignControlGroupChannelsFailure: false,
  campaignControlGroupChannelsError: false,
  loaded: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ENABLE_CONTROL_GROUPS:
      return { ...state };

    case ENABLE_CONTROL_GROUPS_SUCCESS:
      return {
        ...state,
        statusControlGroup: {
          ...state.statusControlGroup,
          ...action.payload,
        },
        enableControlGroup: 'true',
      };

    case CREATE_CONTROL_GROUPS:
      return { ...state };

    case GET_CONTROL_GROUPS_SUCCESS:
      return {
        ...state,
        controlGroups: { ...action.payload },
      };

    case GET_CAMPAIGNS_WITH_CONTROL_GROUP:
      return {
        ...state,
        controlGroups: { ...action.payload },
        running_without_global_control_group:
          action.payload?.global_control_group
            ?.running_without_global_control_group &&
          action.payload?.global_control_group
            ?.running_without_global_control_group,
        running_with_global_control_group:
          action.payload?.global_control_group
            ?.running_with_global_control_group &&
          action.payload?.global_control_group
            ?.running_with_global_control_group,
      };

    case GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS:
      return {
        ...state,
        campaignControlGroupChannels: { ...action.payload },
        loaded: true,
        campaignControlGroupChannelsSuccess: false,
      };

    case GET_STATUS_GLOBAL_CONTROL_GROUP_SUCCESS:
      return {
        ...state,
        statusControlGroup: {
          ...action.payload,
        },
        campaignControlGroupChannels: { ...action.payload },
        statusControlGroupSuccess: false,
      };

    case CREATE_CONTROL_GROUPS_SUCCESS:
      return {
        ...state,
        controlGroups: { ...action.payload },
        controlGroupsSuccess: true,
      };

    case CREATE_CONTROL_GROUPS_FAILURE:
      return {
        ...state,
        controlGroupsFailure: true,
        controlGroupsError: action.payload,
      };

    case CREATE_CONTROL_GROUPS_SUCCESS_RESET:
      return {
        ...state,
        controlGroupsSuccess: false,
      };

    case ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS:
      return {
        ...state,
        campaignControlGroupChannels: action.payload,
        campaignControlGroupChannelsSuccess: true,
        loaded: true,
      };

    case ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_FAILURE:
      return {
        ...state,
        campaignControlGroupChannelsFailure: true,
        campaignControlGroupChannelsError: action.payload,
      };

    case ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS_RESET:
      return {
        ...state,
        campaignControlGroupChannelsSuccess: false,
      };

    case EDIT_CONTROL_GROUPS:
      return {
        ...state,
        editControlGroups: true,
      };

    case EDIT_CONTROL_GROUPS_RESET:
      return {
        ...state,
        editControlGroups: false,
      };

    case DELETE_CONTROL_GROUPS:
      return {
        ...state,
      };

    case DELETE_CONTROL_GROUPS_SUCCESS:
      return {
        ...state,
        deleteControlGroupsSuccess: true,
        deleteErrorMsg: '',
      };

    case DELETE_CONTROL_GROUPS_SUCCESS_RESET:
      return {
        ...state,
        deleteControlGroupsSuccess: false,
        deleteErrorMsg: action.payload,
      };

    case DOWNLOAD_CONTROL_GROUPS_SUCCESS:
      return {
        ...state,
        downloadControlGroupsSuccess: true,
        errorMsg: '',
      };

    case DOWNLOAD_CONTROL_GROUPS_RESET:
      return {
        ...state,
        downloadControlGroupsSuccess: false,
        errorMsg: action.payload,
      };

    case DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_SUCCESS:
      return {
        ...state,
        downloadSampleFileControlGroupsSuccess: true,
        errorMsg: '',
      };

    case DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_RESET:
      return {
        ...state,
        downloadSampleFileControlGroupsSuccess: false,
        errorMsg: action.payload,
      };

    case GLOBAL_CONTROL_GROUPS_TOGGLE:
      return {
        ...state,
        globalControlGroupToggled: action.payload,
      };

    case RESUBMIT_REQUEST:
      return {
        ...state,
        resubmitRequest: true,
      };

    case RESUBMIT_REQUEST_RESET:
      return {
        ...state,
        resubmitRequest: false,
      };

    default:
      return { ...state };
  }
};
