import {
  ENABLE_CONTROL_GROUPS,
  ENABLE_CONTROL_GROUPS_SUCCESS,
  CREATE_CONTROL_GROUPS,
  CREATE_CONTROL_GROUPS_SUCCESS,
  CREATE_CONTROL_GROUPS_FAILURE,
  CREATE_CONTROL_GROUPS_SUCCESS_RESET,
  DELETE_CONTROL_GROUPS,
  DOWNLOAD_CONTROL_GROUPS,
  DELETE_CONTROL_GROUPS_SUCCESS_RESET,
  GET_CONTROL_GROUPS,
  GET_CONTROL_GROUPS_SUCCESS,
  DOWNLOAD_CONTROL_GROUPS_SUCCESS,
  DOWNLOAD_CONTROL_GROUPS_RESET,
  DELETE_CONTROL_GROUPS_SUCCESS,
  GET_STATUS_GLOBAL_CONTROL_GROUP,
  GET_STATUS_GLOBAL_CONTROL_GROUP_SUCCESS,
  GLOBAL_CONTROL_GROUPS_TOGGLE,
  GET_CAMPAIGNS_WITH_CONTROL_GROUP,
  GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
  GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_FAILURE,
  ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS_RESET,
  EDIT_CONTROL_GROUPS,
  EDIT_CONTROL_GROUPS_RESET,
  RESUBMIT_REQUEST,
  RESUBMIT_REQUEST_RESET,
  DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS,
  DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_SUCCESS,
  DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_RESET,
} from 'redux/constants';

export const enableControlGroups = (item) => ({
  type: ENABLE_CONTROL_GROUPS,
  payload: item,
});

export const enableControlGroupsSuccess = (item) => ({
  type: ENABLE_CONTROL_GROUPS_SUCCESS,
  payload: item,
});

export const getControlGroups = (item) => ({
  type: GET_CONTROL_GROUPS,
  payload: item,
});

export const getControlGroupsSuccess = (items) => ({
  type: GET_CONTROL_GROUPS_SUCCESS,
  payload: items,
});

export const getStatusGlobalControlGroup = (item) => ({
  type: GET_STATUS_GLOBAL_CONTROL_GROUP,
  payload: item,
});

export const getStatusGlobalControlGroupSuccess = (items) => ({
  type: GET_STATUS_GLOBAL_CONTROL_GROUP_SUCCESS,
  payload: items,
});

export const getCampaignControlGroupChannels = (item) => ({
  type: GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
  payload: item,
});

export const getCampaignControlGroupChannelsSuccess = (items) => ({
  type: GET_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS,
  payload: items,
});

export const addCampaignControlGroupChannels = (items) => ({
  type: ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS,
  payload: items,
});

export const addCampaignControlGroupChannelsSuccess = (items) => ({
  type: ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS,
  payload: items,
});

export const addCampaignControlGroupChannelsFailure = (item) => ({
  type: ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_FAILURE,
  payload: item,
});

export const addCampaignControlGroupChannelsReset = () => ({
  type: ADD_CAMPAIGN_CONTROL_GROUPS_CHANNELS_SUCCESS_RESET,
});

export const createControlGroups = (items) => ({
  type: CREATE_CONTROL_GROUPS,
  payload: items,
});

export const createControlGroupsSuccess = (items) => ({
  type: CREATE_CONTROL_GROUPS_SUCCESS,
  payload: items,
});

export const createControlFailure = (item) => ({
  type: CREATE_CONTROL_GROUPS_FAILURE,
  payload: item,
});

export const createControlGroupsSuccessReset = () => ({
  type: CREATE_CONTROL_GROUPS_SUCCESS_RESET,
});

export const editControlGroups = (item) => ({
  type: EDIT_CONTROL_GROUPS,
  payload: item,
});

export const editControlGroupsReset = (item) => ({
  type: EDIT_CONTROL_GROUPS_RESET,
  payload: item,
});

export const deleteCreateControlGroups = (item) => ({
  type: DELETE_CONTROL_GROUPS,
  payload: item,
});

export const deleteCreateControlGroupsSuccess = (item) => ({
  type: DELETE_CONTROL_GROUPS_SUCCESS,
  payload: item,
});

export const deleteCreateControlGroupsSuccessReset = (item) => ({
  type: DELETE_CONTROL_GROUPS_SUCCESS_RESET,
  payload: item,
});

export const downloadCreateControlGroups = (item) => ({
  type: DOWNLOAD_CONTROL_GROUPS,
  payload: item,
});

export const downloadCreateControlGroupsSuccess = () => ({
  type: DOWNLOAD_CONTROL_GROUPS_SUCCESS,
});

export const downloadCreateControlGroupsReset = (item) => ({
  type: DOWNLOAD_CONTROL_GROUPS_RESET,
  payload: item,
});

export const downloadSampleFileControlGroup = (item) => ({
  type: DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS,
  payload: item,
});

export const downloadSampleFileControlGroupSuccess = () => ({
  type: DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_SUCCESS,
});

export const downloadSampleFileControlGroupReset = (item) => ({
  type: DOWNLOAD_SAMPLE_FILE_CONTROL_GROUPS_RESET,
  payload: item,
});

export const globalControlGroupToggle = (item) => ({
  type: GLOBAL_CONTROL_GROUPS_TOGGLE,
  payload: item,
});

export const getCampaignsWithControlCount = (item) => ({
  type: GET_CAMPAIGNS_WITH_CONTROL_GROUP,
  payload: item,
});

export const resubmitRequest = (item) => ({
  type: RESUBMIT_REQUEST,
});

export const resubmitRequestReset = (item) => ({
  type: RESUBMIT_REQUEST_RESET,
});
