import {
  GET_TOTAL_AGENT_COUNT,
  GET_TOTAL_AGENT_COUNT_SUCCESS,
  GET_ACTIVE_CHAT_COUNT,
  GET_ACTIVE_CHAT_COUNT_SUCCESS,
  GET_TOTAL_ACTIVE_CHAT_COUNT_SUCCESS,
  GET_INSIGHTS_DETAILS,
  GET_INSIGHTS_DETAILS_SUCCESS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS_SUCCESS,
  DOWNLOAD_DATA_INSIGHTS_REPORTS_ERROR,
  GET_ACTIVE_AGENT_SESSION_SUCCESS,
  GET_ACTIVE_AGENT_SESSION,
  GET_AUDIENCE_DEMOGRAPHIC,
  GET_AUDIENCE_DEMOGRAPHIC_SUCCESS,
  GET_AUDIENCE_COUNTRY_LIST,
  GET_AUDIENCE_COUNTRY_LIST_SUCCESS,
} from 'redux/constants';

export const getTotalAgentCount = (item) => ({
  type: GET_TOTAL_AGENT_COUNT,
  payload: item,
});

export const getTotalAgentCountSuccess = (item) => ({
  type: GET_TOTAL_AGENT_COUNT_SUCCESS,
  payload: item,
});

export const getActiveAgentSession = (item) => ({
  type: GET_ACTIVE_AGENT_SESSION,
  payload: item,
});

export const getActiveAgentSessionSuccess = (item) => ({
  type: GET_ACTIVE_AGENT_SESSION_SUCCESS,
  payload: item,
});

export const getActiveChatCount = (item) => ({
  type: GET_ACTIVE_CHAT_COUNT,
  payload: item,
});

export const getActiveChatCountSuccess = (item) => ({
  type: GET_ACTIVE_CHAT_COUNT_SUCCESS,
  payload: item,
});

export const getTotalActiveChatCountSuccess = (item) => ({
  type: GET_TOTAL_ACTIVE_CHAT_COUNT_SUCCESS,
  payload: item,
});

export const getInsightsDetails = (item) => ({
  type: GET_INSIGHTS_DETAILS,
  payload: item,
});

export const getInsightsDetailsSuccess = (item) => ({
  type: GET_INSIGHTS_DETAILS_SUCCESS,
  payload: item,
});

export const downloadDataAndInSightsReports = (userId) => ({
  type: DOWNLOAD_DATA_INSIGHTS_REPORTS,
  payload: userId,
});

export const downloadDataAndInSightsReportsSuccess = (items) => ({
  type: DOWNLOAD_DATA_INSIGHTS_REPORTS_SUCCESS,
  payload: items,
});

export const downloadDataAndInSightsReportsError = (items) => ({
  type: DOWNLOAD_DATA_INSIGHTS_REPORTS_ERROR,
  payload: items,
});

export const getAudienceDemographic = (item) => ({
  type: GET_AUDIENCE_DEMOGRAPHIC,
  payload: item,
});

export const getAudienceDemographicSuccess = (item) => ({
  type: GET_AUDIENCE_DEMOGRAPHIC_SUCCESS,
  payload: item,
});

export const getAudienceCountryList = () => ({
  type: GET_AUDIENCE_COUNTRY_LIST,
});

export const getAudienceCountryListSuccess = (item) => ({
  type: GET_AUDIENCE_COUNTRY_LIST_SUCCESS,
  payload: item,
});
