import ScheduleAndGoalsEnums from 'enums/campaigns/scheduleAndGoalsEnums';
import TargetAudienceEnums from 'enums/campaigns/targetAudienceEnums';
import CommonEnums from 'enums/commonEnums';
import createSegementEnums from 'enums/createSegment/createSegementEnums';
import { getStepIndex } from 'helpers/Utils';
import {
  getConvertedStringWithSpace,
  mapDraftDataToScheduleAndGoals,
  mapGeoFenceValues,
} from 'helpers/campaignHelper';
import {
  CAMPAIGNS_GET,
  CAMPAIGNS_GET_SUCCESS,
  CAMPAIGNS_DELETE,
  CAMPAIGNS_DELETE_SUCCESS,
  CAMPAIGNS_DELETE_ERROR,
  CAMPAIGNS_DELETE_CLEAN,
  CAMPAIGNS_ADD,
  CAMPAIGNS_ADD_SUCCESS,
  CAMPAIGNS_ADD_ERROR,
  CAMPAIGNS_ADD_CLEAN,
  CAMPAIGNS_CREATE_TYPE_ADD,
  CAMPAIGNS_CREATE_ADD,
  CAMPAIGNS_CREATE_ADD_SUCCESS,
  CAMPAIGNS_CREATE_ADD_ERROR,
  CAMPAIGNS_CREATE_ADD_CLEAN,
  CLEAR_CAMPAIGN_ON_TYPE_CHANGE,
  CAMPAIGNS_CREATE_TYPE_ADD_SUCCESS,
  SMS_CAMAPAIGNS_TEMPLATES_GET_ALL,
  SMS_CAMAPAIGNS_TEMPLATES_GET_ALL_SUCCESS,
  SMS_CAMAPAIGN_TEMPLATE_DELETE,
  SMS_CAMAPAIGN_TEMPLATE_DELETE_SUCCESS,
  SMS_CAMAPAIGN_TEMPLATE_DELETE_ERROR,
  SMS_CAMAPAIGN_TEMPLATE_DELETE_CLEAN,
  SMS_CAMAPAIGN_TEMPLATE_ADD,
  SMS_CAMAPAIGN_TEMPLATE_ADD_SUCCESS,
  SMS_CAMAPAIGN_TEMPLATE_ADD_ERROR,
  SMS_CAMAPAIGN_TEMPLATE_ADD_CLEAN,
  SMS_CAMAPAIGN_TEMPLATE_UPDATE,
  SMS_CAMAPAIGN_TEMPLATE_UPDATE_SUCCESS,
  SMS_CAMAPAIGN_TEMPLATE_UPDATE_ERROR,
  SMS_CAMAPAIGN_TEMPLATE_UPDATE_CLEAN,
  SCHEDULE_SMS_CAMPAIGN,
  SCHEDULE_SMS_CAMPAIGN_SUCCESS,
  SCHEDULE_SMS_CAMPAIGN_ERROR,
  SCHEDULE_SMS_CAMPAIGN_CLEAN,
  SMS_CAMAPAIGN_TAGS,
  SMS_CAMAPAIGN_TAGS_SUCCESS,
  SMS_CAMAPAIGNS_GET_ALL_SENDER_ID,
  SMS_CAMAPAIGNS_GET_ALL_SENDER_ID_SUCCESS,
  SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID,
  SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_SUCCESS,
  SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_ERROR,
  SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID,
  SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_SUCCESS,
  SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_ERROR,
  SMS_CONTENT_GENERATE_TINY_URL,
  SMS_CONTENT_GENERATE_TINY_URL_SUCCESS,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG_SUCCESS,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG_RESET,
  SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_CLEAN,
  TEST_SMS_CAMPAIGN,
  TEST_SMS_CAMPAIGN_SUCCESS,
  TEST_SMS_CAMPAIGN_ERROR,
  TEST_SMS_CAMPAIGN_CLEAN,
  TEST_EMAIL_CAMPAIGN,
  TEST_EMAIL_CAMPAIGN_SUCCESS,
  TEST_EMAIL_CAMPAIGN_ERROR,
  TEST_EMAIL_CAMPAIGN_CLEAN,
  SMS_CAMPAIGN_SAVE_AS_DRAFT,
  SMS_CAMPAIGN_SAVE_AS_DRAFT_SUCCESS,
  SMS_CAMPAIGN_SAVE_AS_DRAFT_ERROR,
  SMS_CAMPAIGN_SAVE_AS_DRAFT_CLEAN,
  SMS_CAMPAIGN_UPDATE_STEP_INDEX,
  SMS_CAMPAIGN_NAME,
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  FETCH_EMAIL_TEMPLATES_REQUEST,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_FAILURE,
  GET_EMAIL_ADDRESS,
  GET_EMAIL_ADDRESS_SUCCESS,
  SET_SUBJECT,
  SET_PREVIEW_TEXT,
  SET_EMAIL_CONNECTOR,
  SET_SENDER_NAME,
  SET_FROM_EMAIL_ADDRESS,
  SET_REPLY_TO_EMAIL_ADDRESS,
  SET_CC,
  SET_BCC,
  SET_FORM_EMAIL_CREATION,
  SET_EMAIL_TARGET_AUDIENCE,
  RESET_SUCCESS,
  ADD_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  ADD_EMAIL_TEMPLATE_FAILURE,
  ADD_EMAIL_TEMPLATE_ERROR_RESET,
  EMAIL_TEMPLATE_ID,
  SET_DEFAULT_BTN,
  GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST,
  GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST_SUCCESS,
  GET_WHATSAPP_CAMPAIGN_DETAILED_TEMPLATES_LIST,
  TEST_WHATSAPP_CAMPAIGN,
  TEST_WHATSAPP_CAMPAIGN_SUCCESS,
  TEST_WHATSAPP_CAMPAIGN_ERROR,
  TEST_WHATSAPP_CAMPAIGN_CLEAN,
  SET_CAMPAIGN_INBOX_ID,
  CLEAN_SELECTED_EMAIL_TEMPLATE,
  GET_CAMPAIGN_INFO_BY_ID_SUCCESS,
  GET_CAMPAIGN_INFO_BY_ID,
  SET_WHATSAPP_CAMPAIGN_TEMPLATE_CATEGORY,
  SMS_CONTENT_GENERATE_TINY_URL_CLEAN,
  SET_CAMPAIGN_CHANNEL_ID,
  DELETE_EMAIL_CAMPAIGN_TEMPLATE_SUCCESS,
  DELETE_EMAIL_CAMPAIGN_TEMPLATE,
  DELETE_EMAIL_CAMPAIGN_TEMPLATE_FAILURE,
  DELETE_EMAIL_CAMPAIGN_TEMPLATE_CLEANUP,
  GET_PUSH_TEMPLATE,
  GET_PUSH_TEMPLATE_SUCCESS,
  GET_PUSH_TEMPLATE_ERROR,
  CLEAN_PUSH_TEMPLATE,
  GET_INAPP_TEMPLATE,
  GET_INAPP_TEMPLATE_SUCCESS,
  GET_INAPP_TEMPLATE_ERROR,
  CLEAN_INAPP_TEMPLATE,
  SAVE_INAPP_TEMPLATE,
  SAVE_INAPP_TEMPLATE_FAILURE,
  SAVE_INAPP_TEMPLATE_SUCCESS,
  UPDATE_INAPP_TEMPLATE,
  UPDATE_INAPP_TEMPLATE_SUCCESS,
  UPDATE_INAPP_TEMPLATE_FAILURE,
  ADD_GEO_FENCE,
  UPDATE_GEO_FENCE,
  GET_GEO_FENCE,
  DELETE_GEO_FENCE,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE_SUCCESS,
  SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE_RESET,
  RESET_SELECTED_INAPP_TEMPLATE,
  CLEAR_INAPP_SUCCESS_STATUS,
  SELECT_TEMPLATE_TAB,
  SAVE_PUSH_TEMPLATE,
  SAVE_PUSH_TEMPLATE_SUCCESS,
  RESET_SELECTED_PUSH_TEMPLATE,
  CLEAR_PUSH_SUCCESS_STATUS,
  UPDATE_PUSH_TEMPLATE_SUCCESS,
  UPDATE_PUSH_TEMPLATE_FAILURE,
  UPDATE_PUSH_TEMPLATE,
  SAVE_PUSH_TEMPLATE_FAILURE,
  DELETE_PUSH_TEMPLATE,
  DELETE_PUSH_TEMPLATE_SUCCESS,
  DELETE_PUSH_TEMPLATE_FAILURE,
  PUSH_SEARCH_TEMPLATE,
  PUSH_SEARCH_TEMPLATE_SUCCESS,
  PUSH_SEARCH_TEMPLATE_FAILURE,
  SELECTED_PUSH_TEMPLATE,
  DELETE_INAPP_TEMPLATE,
  DELETE_INAPP_TEMPLATE_SUCCESS,
  DELETE_INAPP_TEMPLATE_FAILURE,
  DELETE_INAPP_TEMPLATE_CLEANUP,
  GENERATE_PERSONALIZED_PUSH_MSG,
  GENERATE_PERSONALIZED_PUSH_MSG_SUCCESS,
  GENERATE_PERSONALIZED_PUSH_MSG_RESET,
  PUSH_CONTENT_GENERATE_TINY_URL,
  PUSH_CONTENT_GENERATE_TINY_URL_SUCCESS,
  PUSH_CONTENT_GENERATE_TINY_URL_CLEAN,
  RESET_GEO_FENCE,
  TEST_PUSH_CAMPAIGN,
  TEST_PUSH_CAMPAIGN_SUCCESS,
  TEST_PUSH_CAMPAIGN_ERROR,
  TEST_PUSH_CAMPAIGN_CLEAN,
  TOGGLE_SWITCH,
  GET_BEST_TIME_FOR_USERS,
  GET_BEST_TIME_FOR_USERS_SUCCESS,
  GET_BEST_TIME_FOR_USERS_CLEANUP,
  CHECK_CAMPAIGN_NAME,
  CHECK_CAMPAIGN_NAME_SUCCESS,
  CHECK_CAMPAIGN_NAME_ERROR,
  TEST_INAPP_CAMPAIGN,
  TEST_INAPP_CAMPAIGN_SUCCESS,
  TEST_INAPP_CAMPAIGN_ERROR,
  TEST_INAPP_CAMPAIGN_CLEAN,
} from 'redux/constants';

const INIT_STATE = {
  loadedCampaigns: false,
  campaigns: [],
  createCampaign: {
    selectAudience: {},
    contentConfiguration: {},
    scheudleAndGoals: {},
  },
  smsCampaignTemplates: { totalCount: 0, templates: [] },
  campaignTags: [],
  senderIds: [],
  templateListBasedOnSenderId: [],
  convertedTinyUrls: [],
  convertedPersonalizedMsg: {},
  stepIndex: 0,
  saveAsDraftResponse: {},
  templates: [],
  loading: false,
  error: null,
  emailCampaignTemplates: { totalCount: 0, savedTemplates: [] },
  subject: '',
  previewText: '',
  emailConnector: '',
  senderName: '',
  fromEmailAddress: [],
  replyToEmailAddress: '',
  cc: '',
  bcc: '',
  emailAddress: [],
  formEmailCreation: {
    subject: '',
    emailConnector: '',
    senderName: '',
    fromEmailAddress: [],
    replyToEmailAddress: '',
    cc: '',
    bcc: '',
  },
  emailTemplate: null,
  emailTempId: null,
  templateListWABasedOnConnectorID: [],
  templateListWADetailedList: [],
  // WhatsApp Test Campaign
  errorTestWAAdd: {},
  successTestWAAdd: false,
  loadingTestWAAdd: false,
  inboxId: '',
  defaultBtn: true,
  campaignInfo: {},
  stepId: '',
  emailTemplateDelete: {
    deleteSuccess: false,
    deleteErrorMessage: null,
    deleteLoading: false,
  },
  inappTemplate: null,
  inappUpdateTemplate: null,
  pushTemplate: {
    templates: [],
    total_count: 0,
  },
  pushTemplateDelete: {
    deleteSuccess: false,
    deleteErrorMessage: null,
    deleteLoading: false,
  },
  updatePushTemplate: {
    template: null,
    updateSuccess: false,
    updateLoading: false,
  },
  selectedPushTemplate: null,
  geoFenceLocationData: [],
  convertedPersonalizedMsgTitle: {
    android: {},
    ios: {},
  },
  convertedPersonalizedPushMsg: {
    android: {},
    ios: {},
  },
  currentTab: 1,
  inappTemplateDelete: {
    deleteSuccess: false,
    deleteErrorMessage: null,
    deleteLoading: false,
  },
  savePushTemplate: {
    savetemplate: null,
    saveSuccess: false,
    saveLoading: false,
  },
  convertedPushTinyUrls: {
    android: [],
    ios: [],
  },
  toggleEnabled: false,
  bestTimeForUsersList: {},
  loadingBestTimeForUsers: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAMPAIGNS_GET:
      return { ...state, loadedCampaigns: true };

    case CAMPAIGNS_GET_SUCCESS:
      return { ...state, campaigns: action.payload };

    case CAMPAIGNS_DELETE:
      return {
        ...state,
        errorDelete: {},
        successDelete: false,
        loadingDelete: true,
      };

    case CAMPAIGNS_DELETE_SUCCESS:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (item) => item.id !== action.payload.payload.id
        ),
        errorDelete: {},
        successDelete: true,
        loadingDelete: false,
      };

    case CAMPAIGNS_DELETE_ERROR:
      return {
        ...state,
        errorDelete: action.payload,
        successDelete: false,
        loadingDelete: false,
      };

    case CAMPAIGNS_DELETE_CLEAN:
      return {
        ...state,
        errorDelete: {},
        successDelete: false,
        loadingDelete: false,
      };

    case CAMPAIGNS_ADD:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case CAMPAIGNS_ADD_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let newCampaigns = state.campaigns;
      if (action.payload.payload.id) {
        // update record in list
        newCampaigns = newCampaigns.map((item) =>
          item.id === action.payload.payload.id ? action.payload.response : item
        );
      } else {
        // add record in list
        newCampaigns = [...newCampaigns, action.payload.response];
      }
      return {
        ...state,
        campaigns: newCampaigns,
        loaded: true,
        errorAdd: {},
        successAdd: true,
        loadingAdd: false,
      };

    case CAMPAIGNS_ADD_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successAdd: false,
        loadingAdd: false,
      };

    case CAMPAIGNS_ADD_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successAdd: false,
        loadingAdd: false,
      };

    case SMS_CAMAPAIGNS_TEMPLATES_GET_ALL:
      return { ...state, loadedCampaigns: true };

    case SMS_CAMAPAIGNS_TEMPLATES_GET_ALL_SUCCESS:
      return {
        ...state,
        smsCampaignTemplates: {
          templates: action.payload.response.templates,
          pagination: action.payload.response.pagination,
        },
      };

    case SMS_CAMAPAIGN_TEMPLATE_DELETE:
      return {
        ...state,
        errorDelete: {},
        successDelete: false,
        loadingDelete: true,
      };

    case SMS_CAMAPAIGN_TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        errorDelete: {},
        successDelete: true,
        loadingDelete: false,
      };
    case SMS_CAMAPAIGN_TEMPLATE_DELETE_ERROR:
      return {
        ...state,
        errorDelete: action.payload,
        successDelete: false,
        loadingDelete: false,
      };

    case SMS_CAMAPAIGN_TEMPLATE_DELETE_CLEAN:
      return {
        ...state,
        errorDelete: {},
        successDelete: false,
        loadingDelete: false,
      };

    case SMS_CAMAPAIGN_TEMPLATE_ADD:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case SMS_CAMAPAIGN_TEMPLATE_ADD_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const addTemplate = state.smsCampaignTemplates.templates;
      return {
        ...state,
        smsCampaignTemplates: {
          ...state.smsCampaignTemplates,
          templates: [...addTemplate, action.payload.response],
          totalCount: state.smsCampaignTemplates.totalCount + 1,
        },
        loaded: true,
        errorAdd: {},
        successAdd: true,
        loadingAdd: false,
      };

    case SMS_CAMAPAIGN_TEMPLATE_ADD_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successAdd: false,
        loadingAdd: false,
      };

    case SMS_CAMAPAIGN_TEMPLATE_ADD_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successAdd: false,
        loadingAdd: false,
      };

    case SMS_CAMAPAIGN_TEMPLATE_UPDATE:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case SMS_CAMAPAIGN_TEMPLATE_UPDATE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let updatedTemplates = state.smsCampaignTemplates.templates;

      updatedTemplates = updatedTemplates.map((item) =>
        item.id === action.payload.payload.id ? action.payload.response : item
      );
      return {
        ...state,
        smsCampaignTemplates: {
          ...state.smsCampaignTemplates,
          templates: updatedTemplates,
        },
        loaded: true,
        errorAdd: {},
        successAdd: true,
        loadingAdd: false,
      };

    case SMS_CAMAPAIGN_TEMPLATE_UPDATE_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successAdd: false,
        loadingAdd: false,
      };
    case SMS_CAMAPAIGN_TEMPLATE_UPDATE_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successAdd: false,
        loadingAdd: false,
      };

    case CAMPAIGNS_CREATE_TYPE_ADD:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case CAMPAIGNS_CREATE_TYPE_ADD_SUCCESS:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          selectAudience: {
            ...state.createCampaign.selectAudience,
            ...action.payload,
          },
        },
        loaded: true,
        errorAdd: {},
        successAdd: true,
        loadingAdd: false,
      };

    case CAMPAIGNS_CREATE_ADD:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case CAMPAIGNS_CREATE_ADD_SUCCESS:
      return {
        ...state,
        createCampaign: { ...state.createCampaign, ...action.payload },
        loaded: true,
        errorAdd: {},
        successAdd: false,
        loadingAdd: false,
      };

    case CAMPAIGNS_CREATE_ADD_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successAdd: false,
        loadingAdd: false,
      };

    case CAMPAIGNS_CREATE_ADD_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        createCampaign: {},
        successAdd: false,
        loadingAdd: false,
      };

    case CLEAR_CAMPAIGN_ON_TYPE_CHANGE:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        createCampaign: {
          selectAudience: { ...action.payload },
          contentConfiguration: {},
          scheudleAndGoals: {},
        },
        inboxId: '',
        successAdd: false,
        loadingAdd: false,
        formEmailCreation: {
          subject: '',
          emailConnector: '',
          senderName: '',
          fromEmailAddress: [],
          replyToEmailAddress: '',
          cc: '',
          bcc: '',
        },
        saveAsDraftResponse: {},
        emailTemplate: null,
        selectedPushTemplate: null,
        geoFenceLocationData: [],
      };

    case SCHEDULE_SMS_CAMPAIGN:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case SCHEDULE_SMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, action.payload],
        smsCampaignTemplates: {
          selectAudience: {},
          contentConfiguration: {},
          scheudleAndGoals: {},
        },
        loaded: true,
        errorAdd: {},
        successAdd: true,
        loadingAdd: false,
      };

    case TEST_SMS_CAMPAIGN:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successAdd: false,
        loadingAdd: true,
      };

    case TEST_SMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successAdd: true,
        loadingAdd: false,
      };

    case TEST_SMS_CAMPAIGN_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successAdd: false,
        loadingAdd: false,
      };

    case TEST_SMS_CAMPAIGN_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successAdd: false,
        loadingAdd: false,
      };

    case TEST_EMAIL_CAMPAIGN:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successEmailAdd: false,
        loadingAdd: true,
      };

    case TEST_EMAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successEmailAdd: true,
        loadingAdd: false,
      };

    case TEST_EMAIL_CAMPAIGN_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successEmailAdd: false,
        loadingAdd: false,
      };

    case TEST_EMAIL_CAMPAIGN_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successEmailAdd: false,
        loadingAdd: false,
      };

    case TEST_PUSH_CAMPAIGN:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successPushAdd: false,
        loadingAdd: true,
      };

    case TEST_PUSH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successPushAdd: true,
        loadingAdd: false,
      };

    case TEST_PUSH_CAMPAIGN_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successPushAdd: false,
        loadingAdd: false,
      };

    case TEST_PUSH_CAMPAIGN_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successPushAdd: false,
        loadingAdd: false,
      };
    case SCHEDULE_SMS_CAMPAIGN_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successAdd: false,
        loadingAdd: false,
      };

    case SCHEDULE_SMS_CAMPAIGN_CLEAN:
      return {
        ...state,
        createCampaign: {
          selectAudience: {},
          contentConfiguration: {},
          scheudleAndGoals: {},
        },
        campaignInfo: {},
        stepIndex: 0,
        loaded: true,
        errorAdd: {},
        successAdd: false,
        loadingAdd: false,
        saveAsDraftResponse: {},
        formEmailCreation: {
          subject: '',
          emailConnector: '',
          senderName: '',
          fromEmailAddress: [],
          replyToEmailAddress: '',
          cc: '',
          bcc: '',
        },
        emailTemplate: null,
        inappTemplate: null,
        stepId: '',
        geoFenceLocationData: [],
        selectedPushTemplate: null,
      };

    case SMS_CAMAPAIGN_TAGS:
      return {
        ...state,
        loadedCampaigns: true,
      };

    case SET_DEFAULT_BTN:
      return {
        ...state,
        defaultBtn: action.payload,
      };

    case SMS_CAMAPAIGN_TAGS_SUCCESS:
      return {
        ...state,
        campaignTags: action.payload,
      };
    case SMS_CAMAPAIGNS_GET_ALL_SENDER_ID:
      return { ...state, loadedCampaigns: true };

    case SMS_CAMAPAIGNS_GET_ALL_SENDER_ID_SUCCESS:
      return {
        ...state,
        senderIds: action.payload,
      };
    case SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID:
      return { ...state, loadedCampaigns: true };

    case SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_SUCCESS:
      return {
        ...state,
        templateListBasedOnSenderId: action.payload,
      };
    case SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_ERROR:
      return {
        ...state,
        errorAdd: action.payload,
      };
    case SMS_CAMAPAIGNS_GET_ALL_TEMPLATES_BASED_SENDER_ID_CLEAN:
      return {
        ...state,
        errorAdd: {},
        createCampaign: {
          ...state.createCampaign,
          contentConfiguration: {
            ...state.createCampaign.contentConfiguration,
            defaultmessage: '',
          },
        },
      };
    case SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID:
      return { ...state, loadedCampaigns: true };

    case SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_SUCCESS:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          contentConfiguration: {
            ...state.createCampaign.contentConfiguration,
            defaultmessage: action.payload.template.message,
          },
        },
      };
    case SMS_CAMAPAIGNS_GET_TEMPLATES_MESSAGE_BY_TEMPLATE_ID_ERROR:
      return {
        ...state,
        errorAdd: action.payload,
      };

    case SMS_CONTENT_GENERATE_TINY_URL:
      return { ...state, loadedCampaigns: true };

    case SMS_CONTENT_GENERATE_TINY_URL_SUCCESS:
      return {
        ...state,
        convertedTinyUrls: [...state.convertedTinyUrls, ...action.payload],
      };
    case SMS_CONTENT_GENERATE_TINY_URL_CLEAN:
      return {
        ...state,
        convertedTinyUrls: [],
      };

    case SMS_CONTENT_GENERATE_PERSONALIZED_MSG:
      return { ...state, loadedCampaigns: true };

    case SMS_CONTENT_GENERATE_PERSONALIZED_MSG_SUCCESS:
      return {
        ...state,
        convertedPersonalizedMsg: {
          existing_msg: action.payload.personalizedMsg.existing_msg,
          personalize_message:
            action.payload.personalizedMsg.personalize_message,
          personalise_mapping_attribute:
            action.payload.personalizedMsg.personalise_mapping_attribute,
        },
      };

    case SMS_CONTENT_GENERATE_PERSONALIZED_MSG_RESET:
      return {
        ...state,
        convertedPersonalizedMsg: '',
      };

    case SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE:
      return { ...state, loadedCampaigns: true };

    case SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE_SUCCESS:
      return {
        ...state,
        convertedPersonalizedMsgTitle: {
          ...state.convertedPersonalizedMsgTitle,
          ...action.payload.personalizedMsg,
        },
      };

    case SMS_CONTENT_GENERATE_PERSONALIZED_MSG_TITLE_RESET:
      return {
        ...state,
        ...(state.convertedPersonalizedMsgTitle[action.payload.componentType] =
          {}),
      };
    case SMS_CAMPAIGN_SAVE_AS_DRAFT:
      return {
        ...state,
        loaded: false,
        saveError: {},
        saveSuccess: false,
        saveloading: true,
      };

    case SMS_CAMPAIGN_SAVE_AS_DRAFT_SUCCESS:
      return {
        ...state,
        saveAsDraftResponse: action.payload.response,
        selectAudience: {
          ...state.createCampaign.selectAudience,
          ...action.payload,
        },
        loaded: true,
        saveError: {},
        saveSuccess: true,
        saveloading: false,
      };

    case SMS_CAMPAIGN_SAVE_AS_DRAFT_ERROR:
      return {
        ...state,
        loaded: true,
        saveError: action.payload,
        saveSuccess: false,
        saveloading: false,
      };

    case SMS_CAMPAIGN_SAVE_AS_DRAFT_CLEAN:
      return {
        ...state,
        loaded: true,
        saveError: {},
        saveSuccess: false,
        saveloading: false,
      };

    case SMS_CAMPAIGN_UPDATE_STEP_INDEX:
      return {
        ...state,
        stepIndex: action.payload,
      };

    case SMS_CAMPAIGN_NAME:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          selectAudience: {
            ...state.createCampaign.selectAudience,
            campaignName: action.payload,
          },
        },
      };

    case FETCH_TEMPLATES_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload,
      };

    case FETCH_TEMPLATES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_EMAIL_TEMPLATES_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        emailCampaignTemplates: {
          totalCount: action.payload.total_count,
          savedTemplates: action.payload.email_templates,
        },
        error: null,
      };

    case FETCH_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        emailCampaignTemplates: { totalCount: 0, savedTemplates: [] },
        error: action.payload,
      };

    case GET_EMAIL_ADDRESS:
      return { ...state };

    case GET_EMAIL_ADDRESS_SUCCESS:
      return {
        ...state,
        emailAddress: action.payload,
      };

    case SET_SUBJECT:
      return { ...state, subject: action.payload };

    case SET_PREVIEW_TEXT:
      return { ...state, previewText: action.payload };

    case SET_EMAIL_CONNECTOR:
      return { ...state, emailConnector: action.payload };

    case SET_SENDER_NAME:
      return { ...state, senderName: action.payload };

    case SET_FROM_EMAIL_ADDRESS:
      return { ...state, fromEmailAddress: action.payload };

    case SET_REPLY_TO_EMAIL_ADDRESS:
      return { ...state, replyToEmailAddress: action.payload };

    case SET_CC:
      return { ...state, cc: action.payload };

    case SET_BCC:
      return { ...state, bcc: action.payload };

    case SET_FORM_EMAIL_CREATION:
      return {
        ...state,
        formEmailCreation: { ...state.formEmailCreation, ...action.payload },
      };

    case SET_EMAIL_TARGET_AUDIENCE:
      return {
        ...state,
        createCampaign: {
          ...state.createCampaign,
          selectAudience: { ...action.payload },
        },
      };

    case RESET_SUCCESS:
      return {
        ...state,
        successAdd: false,
      };

    case ADD_EMAIL_TEMPLATE:
      return {
        ...state,
      };

    case ADD_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailTemplate: {
          ...state.emailTemplate,
          ...action.payload,
          success: action.payload?.success || null,
        },
      };

    case ADD_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        emailTemplate: {
          ...action.payload.payload,
          id: state.emailTemplate?.id || null,
          error: action.payload.error,
          success: false,
        },
      };
    case ADD_EMAIL_TEMPLATE_ERROR_RESET:
      return {
        ...state,
        emailTemplate: {
          ...state.emailTemplate,
          error: null,
          success: null,
        },
      };

    case EMAIL_TEMPLATE_ID:
      return {
        ...state,
        emailTempId: action.payload,
      };

    case GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST:
      return { ...state };

    case GET_WHATSAPP_CAMPAIGN_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        templateListWABasedOnConnectorID: Object.entries(
          action.payload.data
        ).map(([key, valueList]) => {
          return valueList.map((value) => {
            return {
              value: value.name,
              label: getConvertedStringWithSpace(value.name),
              category: getConvertedStringWithSpace(key),
            };
          });
        }),
      };

    case GET_WHATSAPP_CAMPAIGN_DETAILED_TEMPLATES_LIST:
      return {
        ...state,
        templateListWADetailedList: Object.entries(action.payload.data)
          .map(([key, value]) => {
            return value.map((e) => {
              e['template_type'] = key;
              return e;
            });
          })
          .flat(),
      };

    // WhatsApp Test Campaign
    case TEST_WHATSAPP_CAMPAIGN:
      return {
        ...state,
        loaded: false,
        errorTestWAAdd: {},
        successTestWAAdd: false,
        loadingTestWAAdd: true,
      };

    case TEST_WHATSAPP_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loaded: true,
        errorTestWAAdd: {},
        successTestWAAdd: true,
        loadingTestWAAdd: false,
      };

    case TEST_WHATSAPP_CAMPAIGN_ERROR:
      return {
        ...state,
        loaded: true,
        errorTestWAAdd: action.payload,
        successTestWAAdd: false,
        loadingTestWAAdd: false,
      };

    case TEST_WHATSAPP_CAMPAIGN_CLEAN:
      return {
        ...state,
        loaded: true,
        errorTestWAAdd: {},
        successTestWAAdd: false,
        loadingTestWAAdd: false,
      };

    case SET_CAMPAIGN_INBOX_ID:
      return {
        ...state,
        inboxId: action.payload,
      };

    case SET_CAMPAIGN_CHANNEL_ID:
      return {
        ...state,
        channelId: action.payload.channelId,
        channelTypes: action.payload.channelType,
      };

    case GET_CAMPAIGN_INFO_BY_ID:
      return {
        ...state,
      };

    case GET_CAMPAIGN_INFO_BY_ID_SUCCESS: {
      const { campaign } = action.payload;
      let checkCampaignPersonalAttributes = false;
      let checkTinyUrls = false;
      if (
        campaign?.personalise_mapping_attribute &&
        Object.keys(campaign.personalise_mapping_attribute).length
      ) {
        checkCampaignPersonalAttributes = true;
      }

      if (
        campaign?.campaignable?.tiny_urls &&
        campaign.campaignable.tiny_urls?.length > 0
      ) {
        checkTinyUrls = true;
      }
      let triggerCriteriaInapp;
      if (campaign?.campaign_type === CommonEnums.CHANNEL_INAPP) {
        if (
          campaign?.contact_event_filter?.filter_hash?.included_filters
            ?.filters[0]?.filters[0]?.name ===
          CommonEnums.INAPP_CONTENT_TYPE.APP_OPEN
        ) {
          triggerCriteriaInapp = TargetAudienceEnums.INAPP_APP_OPEN;
        } else {
          triggerCriteriaInapp = TargetAudienceEnums.INAPP_EVENT;
        }
      }

      let mappedCampaignObj = {
        ...state,
        campaignInfo: action.payload,
        saveAsDraftResponse: action.payload,
        inboxId: campaign?.inbox_id ?? '',
        createCampaign: {
          selectAudience: {
            channelType:
              campaign?.campaign_type === CommonEnums.CHANNEL_INAPP
                ? CommonEnums.INAPP
                : campaign?.campaign_type.toLowerCase(),
            campaignType: campaign?.scheduling_type ?? '',
            campaignName: campaign?.title ?? '',
            campaignContentType:
              campaign?.campaignable?.campaign_content_type ?? '',
            audience: campaign?.select_audience ?? '',
            exclude_users: campaign?.exclude_users ?? '',
            selectedUserAttribute: campaign?.selected_contact_attribute ?? '',
            audience_type: campaign?.select_audience,
            ...(campaign?.campaign_type === CommonEnums.PUSH &&
              campaign?.scheduling_type ===
                ScheduleAndGoalsEnums.LOCATION_TRIGGERED && {
                geofence_list: mapGeoFenceValues(
                  [...campaign?.geofences_attributes],
                  true
                ),
              }),
            selectedTargetPlatforms:
              campaign?.campaign_type === CommonEnums.CHANNEL_INAPP
                ? campaign?.campaign_target_platform
                : campaign?.campaignable?.selected_target_platforms,
            inAppTriggerCriteria: triggerCriteriaInapp,
            included_filters: {
              ...campaign?.segment_data?.filter_hash.included_filters,
            },
            excluded_filters: {
              ...(Object.keys(
                campaign?.segment_data?.filter_hash?.excluded_filters
              ).length > 0
                ? { ...campaign?.segment_data?.filter_hash.excluded_filters }
                : {
                    filter_operator: createSegementEnums.INITIALVALUES.AND,
                    filters: [
                      {
                        filter_operator: createSegementEnums.INITIALVALUES.OR,
                        filter_type:
                          createSegementEnums.INITIALVALUES.NESTED_FILTERS,
                        filters: [
                          {
                            filter_type:
                              createSegementEnums.INITIALVALUES.FILTER_BY_USERS,
                          },
                        ],
                      },
                    ],
                  }),
            },
            draftSegmentId: campaign?.segment_data?.id || '',
            active_global_status: campaign?.active_global_status || '',
            active_campaign_status: campaign?.active_campaign_status || '',
            random_allocation: campaign?.random_allocation || '',
          },
          contentConfiguration: {
            smsSender:
              (campaign?.channel_info?.sender_id ||
                campaign?.channel_info?.phone_number) ??
              '',
            templateId: campaign?.campaignable?.template_id ?? '',
            message: campaign?.message ?? '',
            templateRecordId: campaign?.campaignable?.template_record_id ?? '',
            personalize: checkCampaignPersonalAttributes,
            tinyUrlConversion: checkTinyUrls,
            actualMessage: campaign?.message ?? '',
          },
        },
        emailTemplate: {
          id: campaign?.campaignable?.email_template_id ?? null,
        },
        stepId: campaign?.campaign_state ?? '',
        stepIndex: campaign?.campaign_state
          ? getStepIndex(campaign.campaign_state)
          : 0,
      };
      if (
        campaign?.scheduling_type === ScheduleAndGoalsEnums.EVENT_TRIGGERED ||
        campaign?.campaign_type === CommonEnums.CHANNEL_INAPP ||
        campaign?.scheduling_type === ScheduleAndGoalsEnums.DEVICE_TRIGGERED ||
        campaign?.scheduling_type === ScheduleAndGoalsEnums.LOCATION_TRIGGERED
      ) {
        mappedCampaignObj.createCampaign.selectAudience.triggerCriteria = {
          included_filters:
            campaign?.contact_event_filter?.filter_hash?.included_filters,
          time_multiplier:
            campaign?.campaign_scheduler?.time_multiplier ??
            TargetAudienceEnums.TIME_MULTIPLIER,
          time_value: campaign?.campaign_scheduler?.time_value,
          trigger_attr:
            campaign?.campaign_scheduler?.trigger_attr ??
            TargetAudienceEnums.IF_ACTION,
          trigger_message_type: campaign?.campaign_scheduler?.trigger_attr
            ? TargetAudienceEnums.WITH_DELAY
            : TargetAudienceEnums.IMMEDIATELY,
          trigger_relation:
            campaign?.campaign_scheduler?.trigger_relation ??
            TargetAudienceEnums.AFTER,
        };
      }
      if (
        campaign?.personalise_mapping_attribute &&
        Object.keys(campaign?.personalise_mapping_attribute)
      ) {
        mappedCampaignObj.convertedPersonalizedMsg = {
          existing_msg: campaign?.message ?? '',
          personalize_message: campaign?.message ?? '',
          personalise_mapping_attribute:
            campaign?.personalise_mapping_attribute ?? {},
        };

        mappedCampaignObj.convertedPersonalizedPushMsg = {
          android: {
            existing_msg: campaign?.message ?? '',
            personalize_message: campaign?.message ?? '',
            personalise_mapping_attribute:
              campaign?.personalise_mapping_attribute ?? {},
          },
          ios: {
            existing_msg: campaign?.message ?? '',
            personalize_message: campaign?.message ?? '',
            personalise_mapping_attribute:
              campaign?.personalise_mapping_attribute ?? {},
          },
        };
      }

      if (
        campaign?.personalise_title_mapping_attribute &&
        Object.keys(campaign?.personalise_title_mapping_attribute)
      ) {
        mappedCampaignObj.convertedPersonalizedMsgTitle = {
          android: {
            existing_msg: campaign?.message ?? '',
            personalize_message: campaign?.message ?? '',
            personalise_mapping_attribute:
              campaign?.personalise_mapping_attribute ?? {},
          },
          ios: {
            existing_msg: campaign?.message ?? '',
            personalize_message: campaign?.message ?? '',
            personalise_mapping_attribute:
              campaign?.personalise_mapping_attribute ?? {},
          },
        };
      }
      if (campaign?.campaignable?.tiny_urls?.length > 0) {
        mappedCampaignObj.convertedTinyUrls =
          campaign.campaignable.tiny_urls?.map((item) => ({ tinyUrl: item }));
        mappedCampaignObj.convertedPushTinyUrls =
          campaign.campaignable.tiny_urls?.map((item) => ({ tinyUrl: item }));
      }
      if (campaign.campaign_scheduler) {
        mappedCampaignObj.createCampaign.scheudleAndGoals = {
          ...mapDraftDataToScheduleAndGoals(
            campaign.campaign_scheduler,
            campaign?.scheduling_type,
            campaign?.campaign_type
          ),
        };
      }
      if (
        Array.isArray(campaign.campaign_details) &&
        campaign.campaign_details.length > 0
      ) {
        const mappedFormEmailCreation = {
          subject: campaign?.campaign_details[0]?.subject ?? '',
          emailConnector: campaign?.campaign_details[0].channel_email_id ?? '',
          senderName: campaign?.campaign_details[0]?.sender_name ?? '',
          fromEmailAddress:
            campaign?.campaign_details[0].from_email_address ?? '',
          replyToEmailAddress:
            campaign?.campaign_details[0]?.reply_to_email_address ?? '',
        };
        mappedCampaignObj = {
          ...mappedCampaignObj,
          formEmailCreation: mappedFormEmailCreation,
        };
      }
      if (campaign?.in_app) {
        const androidBody = campaign?.in_app?.json_body?.android?.body;
        const iosBody = campaign?.in_app?.json_body?.ios?.body;

        mappedCampaignObj = {
          ...mappedCampaignObj,
          inappTemplate: {
            json_body: {
              ...(androidBody && {
                android: {
                  body: androidBody,
                },
              }),
              ...(iosBody && {
                ios: {
                  body: iosBody,
                },
              }),
            },
            id: campaign?.in_app?.id,
            name: campaign?.in_app?.name,
            status: campaign?.in_app?.status,
            category: campaign?.in_app?.category,
            format: campaign?.in_app?.format,
          },
          toggleEnabled: campaign?.campaignable?.mirror_template,
        };
      }

      return {
        ...mappedCampaignObj,
        ...(campaign?.campaign_type === CommonEnums.PUSH && {
          selectedPushTemplate:
            campaign?.push_template &&
            Object.keys(campaign?.push_template)?.length
              ? { ...campaign?.push_template }
              : { ...campaign?.campaignable },
        }),
        ...(campaign?.scheduling_type ===
          ScheduleAndGoalsEnums.LOCATION_TRIGGERED && {
          geoFenceLocationData: mapGeoFenceValues(
            [...campaign?.geofences_attributes],
            true
          ),
        }),
      };
    }
    case CLEAN_SELECTED_EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplate: null,
      };

    case SET_WHATSAPP_CAMPAIGN_TEMPLATE_CATEGORY:
      return {
        ...state,
        whatsAppTemplateCategory: action.payload,
      };

    case DELETE_EMAIL_CAMPAIGN_TEMPLATE:
      return {
        ...state,
        emailTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: true,
        },
      };
    case DELETE_EMAIL_CAMPAIGN_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailTemplateDelete: {
          deleteSuccess: true,
          deleteErrorMessage: null,
          deleteLoading: false,
        },
      };
    case DELETE_EMAIL_CAMPAIGN_TEMPLATE_FAILURE:
      return {
        ...state,
        emailTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: action.payload.errorMsg,
          deleteLoading: false,
        },
      };
    case DELETE_EMAIL_CAMPAIGN_TEMPLATE_CLEANUP:
      return {
        ...state,
        emailTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: false,
        },
      };

    case GET_PUSH_TEMPLATE:
      return {
        ...state,
        loaded: true,
        pushListSuccess: false,
        pushErrorList: false,
      };
    case GET_PUSH_TEMPLATE_SUCCESS:
      return {
        ...state,
        pushTemplate: {
          templates: action.payload.response.templates ?? [],
          pagination: action?.payload?.response?.pagination,
        },
        pushListSuccess: true,
        pushErrorList: false,
      };
    case GET_PUSH_TEMPLATE_ERROR:
      return {
        ...state,
        pushTemplate: {},
        pushErrorList: action.payload,
        pushListSuccess: false,
      };
    case CLEAN_PUSH_TEMPLATE:
      return {
        ...state,
        pushTemplate: {
          ...state.pushTemplate,
        },
        pushErrorList: false,
        pushListSuccess: false,
        savePushTemplate: {
          savetemplate: null,
          saveSuccess: false,
          saveLoading: false,
        },
        pushTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: false,
        },
        updatePushTemplate: {
          template: null,
          updateSuccess: false,
          updateLoading: false,
        },
        errorAdd: {},
      };

    case SAVE_PUSH_TEMPLATE:
      return {
        ...state,
        savePushTemplate: {
          savetemplate: null,
          saveSuccess: false,
          saveLoading: true,
        },
      };

    case SAVE_PUSH_TEMPLATE_SUCCESS: {
      return {
        ...state,
        savePushTemplate: {
          savetemplate: action.payload.response,
          saveSuccess: true,
          saveLoading: false,
        },
      };
    }

    case SAVE_PUSH_TEMPLATE_FAILURE:
      return {
        ...state,
        savePushTemplate: {
          savetemplate: null,
          saveError: true,
          saveLoading: false,
        },
      };

    case UPDATE_PUSH_TEMPLATE:
      return {
        ...state,
        updatePushTemplate: {
          template: null,
          updateSuccess: false,
          updateLoading: true,
        },
      };

    case UPDATE_PUSH_TEMPLATE_SUCCESS:
      return {
        ...state,
        updatePushTemplate: {
          template: action.payload.response,
          updateSuccess: true,
          updateLoading: false,
        },
      };

    case UPDATE_PUSH_TEMPLATE_FAILURE:
      return {
        ...state,
        updatePushTemplate: {
          template: null,
          updateError: false,
          updateLoading: false,
        },
      };

    case DELETE_PUSH_TEMPLATE:
      return {
        ...state,
        pushTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: true,
        },
      };

    case DELETE_PUSH_TEMPLATE_SUCCESS:
      return {
        ...state,
        pushTemplateDelete: {
          deleteSuccess: true,
          deleteErrorMessage: action.payload.response.message,
          deleteLoading: false,
        },
      };

    case DELETE_PUSH_TEMPLATE_FAILURE:
      return {
        ...state,
        pushTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: false,
        },
      };

    case CLEAR_PUSH_SUCCESS_STATUS:
      return {
        ...state,
        pushTemplate: {
          ...state.pushTemplate,
          error: null,
          success: null,
          updatePushTemplate: null,
        },
      };
    case RESET_SELECTED_PUSH_TEMPLATE:
      return { ...state, selectedPushTemplate: null };

    case SELECTED_PUSH_TEMPLATE:
      return { ...state, selectedPushTemplate: action.payload };

    case GENERATE_PERSONALIZED_PUSH_MSG:
      return { ...state, loadedCampaigns: true };

    case GENERATE_PERSONALIZED_PUSH_MSG_SUCCESS:
      return {
        ...state,
        convertedPersonalizedPushMsg: {
          ...state.convertedPersonalizedPushMsg,
          ...action.payload.personalizedMsg,
        },
      };

    case GENERATE_PERSONALIZED_PUSH_MSG_RESET:
      return {
        ...state,
        ...(state.convertedPersonalizedPushMsg[action.payload.componentType] =
          {}),
      };

    case PUSH_CONTENT_GENERATE_TINY_URL:
      return { ...state, loadedCampaigns: true };

    case PUSH_CONTENT_GENERATE_TINY_URL_SUCCESS:
      return {
        ...state,
        convertedPushTinyUrls: {
          ...state.convertedPushTinyUrls,
          ...action.payload,
        },
      };
    case PUSH_CONTENT_GENERATE_TINY_URL_CLEAN:
      return {
        ...state,
        ...(state.convertedPushTinyUrls[action.payload.componentType] = []),
      };

    case GET_INAPP_TEMPLATE:
      return {
        ...state,
        inAppListSuccess: false,
        inAppErrorList: false,
      };
    case GET_INAPP_TEMPLATE_SUCCESS:
      return {
        ...state,
        inAppTemplateList: action.payload?.in_app_templates,
        inAppPagination: action.payload?.pagination,
        inAppListSuccess: true,
      };
    case GET_INAPP_TEMPLATE_ERROR:
      return {
        ...state,
        inAppErrorList: action.payload,
        inAppListSuccess: false,
      };
    case CLEAN_INAPP_TEMPLATE:
      return {
        ...state,
        inAppErrorList: false,
        inAppListSuccess: false,
      };
    case SAVE_INAPP_TEMPLATE:
      return {
        ...state,
      };

    case SAVE_INAPP_TEMPLATE_SUCCESS: {
      return {
        ...state,
        inappTemplate: {
          ...state.inappTemplate,
          ...action.payload.payload,
          success: action.payload.successStatus ? true : null,
        },
      };
    }

    case SAVE_INAPP_TEMPLATE_FAILURE: {
      const updatedState = {
        ...state,
        inappTemplate: {
          ...action.payload.payload.in_app,
          id: state.inappTemplate?.id || null,
          error: action.payload.error,
          success: false,
          name: state?.inappTemplate?.name ?? '',
          json_body: {
            ...(action?.payload?.payload?.in_app?.json_body?.android?.body && {
              android: {
                body: decodeURIComponent(
                  action.payload.payload.in_app.json_body.android.body
                ),
              },
            }),
            ...(action?.payload?.payload?.in_app?.json_body?.ios?.body && {
              ios: {
                body: decodeURIComponent(
                  action.payload.payload.in_app.json_body.ios.body
                ),
              },
            }),
          },
        },
      };
      return updatedState;
    }

    case UPDATE_INAPP_TEMPLATE:
      return {
        ...state,
      };

    case UPDATE_INAPP_TEMPLATE_SUCCESS:
      return {
        ...state,
        inappTemplate: {
          ...state.inappTemplate,
          ...action.payload.in_app_template,
          success: true,
        },
      };

    case UPDATE_INAPP_TEMPLATE_FAILURE:
      return {
        ...state,
        inappTemplate: {
          ...action.payload.payload.in_app,
          id: state.inappTemplate?.id || null,
          error: action.payload.error,
          success: false,
        },
      };
    case CLEAR_INAPP_SUCCESS_STATUS:
      return {
        ...state,
        inappTemplate: {
          ...state.inappTemplate,
          error: null,
          success: null,
        },
      };
    case RESET_SELECTED_INAPP_TEMPLATE:
      return { ...state, inappTemplate: null };

    case ADD_GEO_FENCE:
      return {
        ...state,
        geoFenceLocationData: [...state.geoFenceLocationData, action.payload],
      };

    case UPDATE_GEO_FENCE:
      return {
        ...state,
        geoFenceLocationData: state.geoFenceLocationData
          .filter((element) => !element._destroy)
          .map((location, index) => {
            if (action.payload.index - 1 === index) {
              return action.payload.values;
            }
            return location;
          }),
      };

    case DELETE_GEO_FENCE:
      return {
        ...state,
        geoFenceLocationData: state.geoFenceLocationData
          .filter((element) => !element._destroy)
          .map((res, index) => {
            if (action.payload.index === index) res['_destroy'] = true;
            return res;
          }),
      };
    case RESET_GEO_FENCE:
      return {
        ...state,
        geoFenceLocationData: [],
      };
    case SELECT_TEMPLATE_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case DELETE_INAPP_TEMPLATE:
      return {
        ...state,
        inappTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: true,
        },
      };
    case DELETE_INAPP_TEMPLATE_SUCCESS:
      return {
        ...state,
        inappTemplateDelete: {
          deleteSuccess: true,
          deleteErrorMessage: null,
          deleteLoading: false,
        },
      };
    case DELETE_INAPP_TEMPLATE_FAILURE:
      return {
        ...state,
        inappTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: action.payload.errorMsg,
          deleteLoading: false,
        },
      };
    case DELETE_INAPP_TEMPLATE_CLEANUP:
      return {
        ...state,
        inappTemplateDelete: {
          deleteSuccess: false,
          deleteErrorMessage: null,
          deleteLoading: false,
        },
      };
    case TOGGLE_SWITCH:
      return {
        ...state,
        toggleEnabled: action.payload,
      };
    case GET_BEST_TIME_FOR_USERS:
      return {
        ...state,
        loadingBestTimeForUsers: true,
      };
    case GET_BEST_TIME_FOR_USERS_SUCCESS:
      return {
        ...state,
        bestTimeForUsersList: { ...action.payload },
        loadingBestTimeForUsers: false,
      };
    case GET_BEST_TIME_FOR_USERS_CLEANUP:
      return {
        ...state,
        bestTimeForUsersList: {},
        loadingBestTimeForUsers: false,
      };

    case CHECK_CAMPAIGN_NAME:
      return {
        ...state,
        errorCampaignName: {},
        successCampaignName: false,
        loadingCheckCampaignName: true,
      };

    case CHECK_CAMPAIGN_NAME_SUCCESS:
      return {
        ...state,
        errorCampaignName: {},
        successCampaignName: action.payload.response,
        loadingCheckCampaignName: false,
      };

    case CHECK_CAMPAIGN_NAME_ERROR:
      return {
        ...state,
        errorCampaignName: action.payload.response.errors,
        successCampaignNamee: false,
        loadingCheckCampaignName: false,
      };

    case TEST_INAPP_CAMPAIGN:
      return {
        ...state,
        loaded: false,
        errorAdd: {},
        successPushAdd: false,
        loadingAdd: true,
      };

    case TEST_INAPP_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successPushAdd: true,
        loadingAdd: false,
      };

    case TEST_INAPP_CAMPAIGN_ERROR:
      return {
        ...state,
        loaded: true,
        errorAdd: action.payload,
        successPushAdd: false,
        loadingAdd: false,
      };

    case TEST_INAPP_CAMPAIGN_CLEAN:
      return {
        ...state,
        loaded: true,
        errorAdd: {},
        successPushAdd: false,
        loadingAdd: false,
      };
    default:
      return { ...state };
  }
};
